import React, { Component, createContext } from 'react';
import { Alert } from 'bootstrap';
import { ReactComponent as InfoIcon } from '../assets/img/icons/alerts/info.svg';
import { ReactComponent as SuccessIcon } from '../assets/img/icons/alerts/success.svg';
import { ReactComponent as WarningIcon } from '../assets/img/icons/alerts/warning.svg';

export const NotificationContext = createContext();

class NotificationContextProvider extends Component {

    state = {
        messages: [],
        audio: [],
        playAudio: this.playAudio.bind(this),
        showMessage: this.showMessage.bind(this),
        info: this.info.bind(this),
        success: this.success.bind(this),
        error: this.error.bind(this),
    }

    playAudio(audio) {
        this.setState({
            audio: [...this.state.audio, audio]
        })
    }

    info(title, body) {
        return this.showMessage({
            variant: "info",
            headingText: title,
            bodyText: body,
            withTimer: true,
            timer: 5000
        })
    }

    success(title, body) {
        return this.showMessage({
            variant: "success",
            headingText: title,
            bodyText: body,
            withTimer: true,
            timer: 5000
        })
    }

    error(title, body) {
        return this.showMessage({
            variant: "danger",
            headingText: title,
            bodyText: body,
            withTimer: true,
            timer: 5000
        })
    }

    showMessage(message) {
        this.setState({
            messages: [...this.state.messages, message],
        });
    }

    _onRemoveAudio(index) {
        this.setState({ audio: this.state.audio.filter((i) => i !== index) })
    }

    _onHide(hideIndex) {
        this.setState({ messages: this.state.messages.filter(index => index !== hideIndex) })
    }

    render() {
        return (
            <NotificationContext.Provider value={this.state}>
                <div style={{ zIndex: 10000, position: 'fixed', top: 16, left: 16, right: 16, pointerEvents: "none" }}>
                    {this.state.messages.map((message, index) => <NotificationMessage key={index} message={message} index={index} onHide={this._onHide.bind(this)} />)}
                    {this.state.audio.map((audio, index) => <NotificationAudio key={index} audio={audio} index={index} onRemoveAudio={this._onRemoveAudio.bind(this)} />)}
                </div>
                {this.props.children}
            </NotificationContext.Provider>
        );
    }
}

export default NotificationContextProvider;

class NotificationAudio extends React.Component {
    constructor(props) {
        super(props);
        this._audioRef = React.createRef()
    }

    componentDidMount() {
        this._audioRef.current.addEventListener("ended", this._removeAudio.bind(this))
    }

    _removeAudio() {
        this.props.onRemoveAudio(this.props.index)
    }

    render() {
        return <audio
            controls={false}
            autoPlay
            playsInline
            ref={this._audioRef} >
            {this.props.audio}
            <source src={this.props.audio} />
        </audio>
    }
}


class NotificationMessage extends React.Component {
    constructor(props) {
        super(props);
        this.alertRef = React.createRef(null)
    }

    componentDidMount() {
        if (this.props.message.withTimer) {
            window.setTimeout(() => this._onHide(), this.props.message.timer)
        }
        this.alert = new Alert(this.alertRef.current)
    }



    _onHide() {
        this.alert.close()
        this.props.onHide(this.props.index)
    }

    _renderIcon() {
        switch (this.props.message.variant) {
            case "success":
                return <SuccessIcon width="24" height="24" className="flex-shrink-0 me-2" />;
            case "danger":
            case "warning":
                return <WarningIcon width="24" height="24" className="flex-shrink-0 me-2" />
            default:
                return <InfoIcon width="24" height="24" className="flex-shrink-0 me-2" />;
        }
    }

    render() {
        return (
            <div ref={this.alertRef} className={`alert alert-${this.props.message.variant} d-flex align-items-center`} style={{ width: "360px", maxWidth: "100%", borderRadius: "5px" }} role="alert">
                {this._renderIcon()}
                <div className="me-3">
                    <strong>{this.props.message.headingText}</strong><br />
                    {this.props.message.bodyText}
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" style={{ position: "absolute", top: 5, right: 5, pointerEvents: "all" }} />
                </div>
            </div>
        );
    }
}
