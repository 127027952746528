import logo from '../../assets/img/logo.svg';

export default function JoiningScreen({ title, error, spin, onRetry, onCancel }) {
    return (
        <div className="container p-3 d-flex flex-column align-items-center mx-auto text-center" style={{ maxWidth: "720px" }}>
            <img src={logo} alt="Video.Taxi Studio" className="mb-5" style={{ width: "200px" }} />
            {spin && <div className={'spinner-grow mb-5 text-primary'} role='status'></div>}

            <h3>{error == null ? title : "An error occured"}</h3>

            {error && <span className="text-muted">{title}</span>}
            {error && <details className="text-muted mb-3" style={{ whiteSpace: 'pre-wrap' }}>
                {error.toString()}
            </details>}

            {(onRetry || onCancel) && <div className="row mt-3">
                {onRetry && <div className="col">
                    <button className="btn btn-primary btn-lg" onClick={onRetry}>Retry</button>
                </div>}
                {onCancel && <div className="col">
                    <button className="btn btn-secondary btn-lg" onClick={onCancel}>Cancel</button>
                </div>}
            </div>}
        </div>
    );
}
