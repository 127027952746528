import moment from 'moment';
import { useState, useEffect, useContext, useRef } from 'react';
import { RtcContext } from '../../../contexts/RtcContext';
import { InputWithValidation } from '../../../helper/Helper';
import { getErrors, hasError, globalErrors } from '../../../helper/ErrorHelpers';

const INITIAL_DATA = {
    duration: 3600,
    expectedVisitors: 10,
    hlsOutput: true,
    name: "",
    quality: "HD",
    chatEnabled: true,
    becomesVod: true,
    passwordEnabled: false,
    ppv: false,
    publicStreaming: true,
    startsAt: null,
    outputs: []
};

const OUTPUT_PRESETS = [
    { name: "Twitter", url: "rtmp://ie.pscp.tv:80/x" },
    { name: "Youtube", url: "rtmp://a.rtmp.youtube.com/live2" },
    { name: "Facebook", url: "rtmps://live-api.facebook.com:443/rtmp/" }
]

const NOOP = () => { };

export default function LivestreamForm({ errors, onChange, onSubmit, initialData: _initialData, forceErrors = false }) {
    const initialData = _initialData || INITIAL_DATA;
    const rtcContext = useContext(RtcContext);
    const isFirst = useRef(true);

    const [data, setData] = useState({
        ...initialData,
        inputUrl: new URL(`/join/${rtcContext.roomName}/canvas`, process.env.REACT_APP_HOST)
    });

    const handleFormChange = (event) => {
        const formData = new FormData(event.currentTarget);

        // In firefox you can delete the value and it will send and empty string.
        // This will set it to the current date / time.
        const date = formData.get("date") || moment().format("YYYY-MM-DD");
        const time = formData.get("time") || moment().add(1, "hours").format("HH:mm:ss");

        // When the checkbox is checked, set startsAt to null.
        const startsAt = formData.get("startNow") === "true" ? null : moment(`${date} ${time}`);

        // Custom outputs
        const outputs = formData.get("outputsEnabled") === "true" ? serializeForm(formData).outputs || [{ urlA: OUTPUT_PRESETS[0].url, nameA: "" }] : [];

        setData({
            ...data,
            name: formData.get("name"),
            duration: formData.get("hours") * 3600 + formData.get("minutes") * 60,
            expectedVisitors: parseInt(formData.get("expectedVisitors")),
            chatEnabled: formData.get("chatEnabled") === "true",
            becomesVod: formData.get("becomesVod") === "true",
            startsAt: startsAt,
            outputs: outputs
        });
    }

    const addCustomOutput = (event) => {
        event.preventDefault();
        setData({
            ...data,
            outputs: data.outputs.concat([{ urlA: OUTPUT_PRESETS[0].url, nameA: "" }])
        });
    }

    const removeCustomOutput = (index, event) => {
        event.preventDefault();
        setData({
            ...data,
            outputs: data.outputs.filter((v, i) => i !== index)
        });
    }

    useEffect(() => {
        onChange(data, isFirst.current);
        isFirst.current = false;
    }, [onChange, data]);

    const hours = Math.floor(data.duration / 3600);
    const minutes = Math.floor((data.duration - hours * 3600) / 60);

    return (
        <form onSubmit={onSubmit} onChange={handleFormChange}>
            <h2>Streaming settings</h2>

            {globalErrors(errors, Object.keys(data))}

            {/* name */}
            <div className="mb-3">
                <label htmlFor="inputName" className="form-label">Name</label>
                <InputWithValidation type="text" name="name" value={data.name} onChange={NOOP} className="form-control" isInvalid={hasError(errors, "name")} forceErrors={forceErrors} id="inputName" placeholder="Give your live session a name" autoComplete="off" />
                <div className="invalid-feedback">{getErrors(errors, "name")}</div>
            </div>

            <div className="row mb-3">
                {/* duration */}
                <div className="col-md-7">
                    <label className="form-label">Duration</label>
                    <div className="row g-0 has-validation">
                        <div className="col-6 pe-1">
                            <select name="hours" className={`form-select ${hasError(errors, "duration") ? "is-invalid" : ""}`} value={hours} onChange={NOOP}>
                                {[...Array(13).keys()].map((hour) => <option key={hour} value={hour}>{hour}h</option>)}
                            </select>
                        </div>
                        <div className="col-6 ps-1">
                            <select name="minutes" className={`form-select ${hasError(errors, "duration") ? "is-invalid" : ""}`} value={minutes} onChange={NOOP}>
                                {[...Array(60).keys()].map((min) => <option key={min} value={min}>{min}m</option>)}
                            </select>
                        </div>
                    </div>
                    {hasError(errors, "duration") && <div className="invalid-feedback d-block">{getErrors(errors, "duration")}</div>}
                </div>

                {/* expected visitors */}
                <div className="col-md-5">
                    <label htmlFor="inputExpectedVisitors" className="form-label">Expected visitors</label>
                    <select className="form-select" id="inputExpectedVisitors" name="expectedVisitors" value={data.expectedVisitors} onChange={NOOP}>
                        <option value={10}>Up to 10</option>
                        <option value={100}>Up to 100</option>
                        <option value={300}>Up to 300</option>
                        <option value={500}>Up to 500</option>
                        <option value={1000}>Up to 1000</option>
                        <option value={3000}>Up to 3000</option>
                        <option value={5000}>Up to 5000</option>
                        <option value={10000}>Up to 10000</option>
                        <option value={15000}>Up to 15000</option>
                        <option value={20000}>Up to 20000</option>
                        <option value={25000}>Up to 25000</option>
                        <option value={30000}>Up to 30000</option>
                    </select>
                </div>
            </div>

            <label className="form-label mb-1">Other settings</label>

            {/* startsAt */}
            <div className="has-validation">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="inputStartNow" name="startNow" value={true} checked={data.startsAt == null} onChange={NOOP} />
                    <label className="form-check-label fw-normal" htmlFor="inputStartNow">Start as soon as possible</label>
                </div>
                {data.startsAt != null && <div className="mb-2">
                    <div className="row mt-1">
                        {/* date */}
                        <div className="col-md-6">
                            <input type="date" className={`form-control ${hasError(errors, "startsAt") ? "is-invalid" : ""}`}
                                value={data.startsAt.format("YYYY-MM-DD")}
                                min={moment().format("YYYY-MM-DD")}
                                max={moment().add(2, "years").format("YYYY-MM-DD")}
                                onChange={NOOP}
                                name="date" />
                        </div>
                        {/* time */}
                        <div className="col-md-6">
                            <input className={`form-control ${hasError(errors, "startsAt") ? "is-invalid" : ""}`}
                                name="time"
                                value={data.startsAt.format("HH:mm")}
                                onChange={NOOP}
                                type="time" />
                        </div>
                    </div>
                    {hasError(errors, "startsAt") && <div className="invalid-feedback d-block">{getErrors(errors, "startsAt")}</div>}
                </div>}
            </div>

            {/* chat */}
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="inputChatEnabled" name="chatEnabled" value={true} checked={data.chatEnabled} onChange={NOOP} />
                <label className="form-check-label fw-normal" htmlFor="inputChatEnabled">Enable public chat</label>
            </div>

            {/* becomesVod */}
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="inputBecomesVod" name="becomesVod" value={true} checked={data.becomesVod} onChange={NOOP} />
                <label className="form-check-label fw-normal" htmlFor="inputBecomesVod">Record as a VOD</label>
            </div>

            {/* outputs */}
            <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" id="inputCustomOutputs" name="outputsEnabled" value={true} checked={data.outputs.length > 0} onChange={NOOP} />
                <label className="form-check-label fw-normal" htmlFor="inputCustomOutputs">Custom outputs</label>
            </div>

            {data.outputs.map((output, index) => {
                const isPredefined = OUTPUT_PRESETS.map((o) => o.url).includes(output.urlA);

                return (
                    <div className="has-validation mb-2">
                        <div key={index} className="row g-1">
                            <div className="col">
                                {isPredefined ? <select name={`outputs[${index}][urlA]`} data-selected={output.urlA} value={isPredefined ? output.urlA : ""} className="form-select" onChange={NOOP}>
                                    {OUTPUT_PRESETS.map((o) => <option key={o.url} value={o.url}>{o.name}</option>)}
                                    <option value="">Custom</option>
                                </select> : <InputWithValidation type="text" name={`outputs[${index}][urlA]`} value={output.urlA} onChange={NOOP} className="form-control" isInvalid={hasError(errors, `outputs[${index}][urlA]`)} forceErrors={forceErrors} placeholder="Stream URL" />}
                                <div className="invalid-feedback">{getErrors(errors, `outputs[${index}][urlA]`)}</div>
                            </div>
                            <div className="col">
                                <InputWithValidation type="text" name={`outputs[${index}][nameA]`} className="form-control" isInvalid={hasError(errors, `outputs[${index}][nameA]`)} forceErrors={forceErrors} value={output.nameA} onChange={NOOP} placeholder="Stream key" />
                                <div className="invalid-feedback">{getErrors(errors, `outputs[${index}][nameA]`)}</div>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-danger rounded-circle d-flex align-items-center justify-content-center" style={{ width: 26, height: 26, marginTop: 5 }} onClick={removeCustomOutput.bind(this, index)}>-</button>
                            </div>
                        </div>
                    </div>
                );
            })}

            {data.outputs.length > 0 && <button className="btn btn-outline-secondary btn-sm rounded-pill" onClick={addCustomOutput}>+ Add output</button>}
        </form>
    );
}

function update(data, keys, value) {
    if (keys.length === 0) {
        // Leaf node
        return value;
    }

    let key = keys.shift();
    if (!key) {
        data = data || [];
        if (Array.isArray(data)) {
            key = data.length;
        }
    }

    // Try converting key to a numeric value
    let index = +key;
    if (!isNaN(index)) {
        // We have a numeric index, make data a numeric array
        // This will not work if this is a associative array
        // with numeric keys
        data = data || [];
        key = index;
    }

    // If none of the above matched, we have an associative array
    data = data || {};

    let val = update(data[key], keys, value);
    data[key] = val;

    return data;
}

function serializeForm(formData) {
    return Array.from((formData).entries())
        .reduce((data, [field, value]) => {
            // eslint-disable-next-line
            let [_, prefix, keys] = field.match(/^([^\[]+)((?:\[[^\]]*\])*)/);

            if (keys) {
                keys = Array.from(keys.matchAll(/\[([^\]]*)\]/g), m => m[1]);
                value = update(data[prefix], keys, value);
            }
            data[prefix] = value;
            return data;
        }, {});
}
