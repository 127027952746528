import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import './i18n';
import App from './App';
import './index.scss';
import appSignal from "./AppSignal";
import { ErrorBoundary } from "@appsignal/react";
import logo from './assets/img/logo.svg';

const FriendlyFallbackComponent = ({ error }) => {
    const timer = useRef(null);

    useEffect(() => {
        timer.current = window.setTimeout(() => document.location.reload(), 5000);
    }, []);

    return (
        <div className="container p-3 d-flex flex-column align-items-center mx-auto text-center" style={{ maxWidth: "720px" }}>
            <img src={logo} alt="Video.Taxi Studio" className="mb-5" style={{ width: "200px" }} />
            <h3>Something unexpected happened</h3>
            <details className="text-muted" style={{ whiteSpace: 'pre-wrap' }} onToggle={() => window.clearTimeout(timer.current)}>
                {error && error.toString()}
            </details>
            <button className="btn btn-primary btn-lg mt-3" onClick={() => document.location.reload()}>
                Reload
            </button>
        </div>
    );
}

const location = document.location.href;

ReactDOM.render(
    <React.StrictMode>
        {appSignal != null ? <ErrorBoundary
            instance={appSignal}
            tags={{ location: location }}
            fallback={(error) => <FriendlyFallbackComponent error={error} />}>
            <App />
        </ErrorBoundary> : <App />}
    </React.StrictMode>,
    document.getElementById('root')
);

// register() on deployment
serviceWorker.unregister();
