import * as React from 'react';

export default function RecordingIndicator() {
    return (
        <div className="d-flex align-items-center badge border border-dark text-dark rounded-pill border-2 text-uppercase">
            <div className="blink"
                style={{
                    marginRight: 5,
                    width: 12,
                    height: 12,
                    backgroundColor: "red",
                    borderRadius: 6
                }} />

            On Air
        </div>
    );
}
