import * as React from 'react';
import { RtcContext } from '../../contexts/RtcContext';

import monitor from '../../assets/img/icons/views/monitor.svg';
import singleUser from '../../assets/img/icons/views/single-user.svg';
import userBoxes from '../../assets/img/icons/views/user-boxes.svg';
import userFree from '../../assets/img/icons/views/user-free.svg';
import userGrid from '../../assets/img/icons/views/user-grid.svg';
import userMonitor from '../../assets/img/icons/views/user-monitor.svg';
import usersMonitor from '../../assets/img/icons/views/users-monitor.svg';

const streamStyles = [
    {
        id: 'solo',
        name: 'Solo',
        imageSource: singleUser
    },
    {
        id: 'grid',
        name: 'Grid',
        imageSource: userBoxes
    },
    {
        id: 'vertical',
        name: 'Vertical',
        imageSource: userGrid
    },
    {
        id: 'solo-grid',
        name: 'Multiple with speaker view',
        imageSource: userFree
    },
    {
        id: 'solo-screen',
        name: 'One speaker aside screen sharing',
        imageSource: userMonitor
    },
    {
        id: 'users-screen',
        name: 'Multiple speakers with screen sharing',
        imageSource: usersMonitor
    },
    {
        id: 'screen',
        name: 'Fullscreen screen sharing',
        imageSource: monitor
    }
];

class ViewStyle extends React.Component {
    static contextType = RtcContext;

    render() {
        return (
            <div className="my-3 d-flex justify-content-center">
                {streamStyles.map(this._renderStreamStyle)}
            </div>
        );
    }

    _renderStreamStyle = (streamStyle) => {
        const style = {
            WebkitMaskRepeat: "no-repeat",
            WebkitMaskPosition: "center",
            WebkitMaskSize: "contain",
            maskImage: 'url(' + streamStyle.imageSource + ')',
            WebkitMaskImage: 'url(' + streamStyle.imageSource + ')'
        };

        const isActive = this.context.layout === streamStyle.id ? "active" : null;

        return (
            <button key={streamStyle.id} className={`view-style-button mx-1 ${isActive}`} onClick={() => this.context.updateLayout(streamStyle.id)} style={style} title={streamStyle.name}>
                {streamStyle.name}
            </button>
        );
    }
}

export default ViewStyle;
