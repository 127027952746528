import Ticker from 'react-ticker';
import React, { useEffect, useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group'
import { getContrastColor } from '../Canvas';

const duration = 300;

export default function ScrollingBanner({ background, children: banner }) {
    const [lastBanner, setLastBanner] = useState(banner);
    const textColor = getContrastColor(background);
    const nodeRef = useRef(null);

    useEffect(() => {
        if (banner && banner !== lastBanner) {
            setLastBanner(banner);
        }
    }, [banner, lastBanner]);


    const classes = {
        enter: "animate__animated",
        enterActive: "animate__slideInUp",
        exit: "animate__animated",
        exitActive: "animate__slideOutDown"
    };

    const bannerStyle = {
        backgroundColor: background,
        color: textColor,
        "--animate-duration": `${duration}ms`
    }

    const currentBanner = banner || lastBanner;

    return (
        <CSSTransition nodeRef={nodeRef} in={banner != null} timeout={duration} unmountOnExit classNames={classes}>
            <div ref={nodeRef} className="canvas-scrolling-banner" style={bannerStyle}>
                {currentBanner && <Ticker speed={8} key={textColor} >
                    {() => <div className="canvas-scrolling-banner-item">{currentBanner.text}</div>}
                </Ticker>}
            </div>
        </CSSTransition>
    );
}
