import { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Basket from '../payment/Basket';
import PaymentForm from '../payment/forms/PaymentForm';
import throttle from 'lodash.throttle';

const STATE_FORM = 0;
const STATE_PAYMENT = 1;

export function PaymentPanel({ client, hideModal, onSuccess, formComponent: FormComponent, estimateQuery, createMutation, updateCacheFn, initialData, prepareVars }) {
    const [attributes, setAttributes] = useState(initialData);
    const [forceErrors, setForceErrors] = useState(false);
    const [formState, setFormState] = useState(STATE_FORM);
    const [lastErrors, setLastErrors] = useState([]);
    const paymentRef = useRef();
    const paymentToken = useRef();
    const onChangeFn = useRef(throttle((data, autoTrigger) => {
        if (autoTrigger !== true) {
            setLastErrors([]);
            setForceErrors(false);
        }
        setAttributes(data);
    }, 400));

    // estimation
    const { data: dataCur, previousData: dataPrev, refetch } = useQuery(estimateQuery, {
        client: client,
        skip: attributes == null,
        variables: prepareVars(attributes),
        onError: (errors) => {
            console.error(errors.graphQLErrors);
            setLastErrors(errors.graphQLErrors)
        }
    });
    const curEstimation = dataCur?.estimation;
    const estimation = (dataCur || dataPrev)?.estimation;

    // custom pollInterval implementation
    // see: https://github.com/apollographql/apollo-client/issues/6391
    const refetchRef = useRef();
    useEffect(() => {
        refetchRef.current = window.setInterval(refetch, 60000);

        return () => {
            window.clearInterval(refetchRef.current);
        };
    }, [refetch]);

    // payment
    const [performPayment, { loading: purchaseLoading }] = useMutation(createMutation, {
        client: client,
        update: updateCacheFn,
    });

    const goToPayment = (event) => {
        if (event != null) event.preventDefault();

        if (curEstimation == null || curEstimation.errors != null) {
            setForceErrors(true);
        } else {
            paymentToken.current = curEstimation.token;
            setFormState(STATE_PAYMENT);
        }
    };

    const renderForm = () => {
        return <FormComponent
            errors={estimation?.errors ?? lastErrors}
            initialData={attributes}
            onChange={onChangeFn.current}
            onSubmit={goToPayment}
            forceErrors={forceErrors}
        />;
    }

    const renderPayment = () => {
        if (purchaseLoading) {
            return <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        }

        return <PaymentForm
            ref={paymentRef}
            token={paymentToken.current} />;
    }

    const doPayment = () => {
        paymentRef.current.getNonce().then((nonce) => {
            return performPayment({
                variables: prepareVars(attributes, nonce)
            });
        }).then((result) => {
            hideModal();
            onSuccess(result);
        }).catch((error) => {
            // eslint-disable-next-line
            if (typeof error !== 'DropInError') {
                setFormState(STATE_FORM);
                setLastErrors(error?.graphQLErrors || [error.message]);
            }
        });
    }

    const renderLoading = () => {
        return (
            <div className="d-flex justify-content-center p-5">
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    let actions;
    if (formState === STATE_FORM) {
        actions = <div className="d-grid gap-2 mx-4 mt-4">
            <button className="btn btn-primary btn-lg text-white fw-bold" disabled={curEstimation == null || (forceErrors === true && curEstimation.errors != null)} onClick={goToPayment}>CONTINUE</button>
            <button className="btn btn-secondary" onClick={hideModal}>Close</button>
        </div>;
    } else {
        actions = <div className="d-grid gap-2 mx-4 mt-4">
            <button className="btn btn-primary btn-lg text-white fw-bold" disabled={purchaseLoading} onClick={doPayment}>BOOK NOW</button>
            <button className="btn btn-secondary" disabled={purchaseLoading} onClick={() => setFormState(STATE_FORM)}>Back</button>
        </div>;
    }

    return (
        <div className="modal-body p-0">
            <div className="row">
                <div className="col-md-7">
                    <div className="p-4 h-100">
                        {formState === STATE_FORM && renderForm()}
                        {formState === STATE_PAYMENT && renderPayment()}
                    </div>
                </div>
                <div className="col-md-5 bg-light py-4 px-0">
                    {estimation != null ? <>
                        <h4 className="mb-2 px-4">Your configuration</h4>
                        <Basket>{estimation.basket}</Basket>
                    </> : renderLoading()}
                    {actions}
                </div>
            </div>
        </div>
    );
}
