import { useContext, useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

import * as api from '../../../api';
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/alerts/info.svg';
import { Center } from '../Sidebar';
import receivingLivestream from "../../../assets/audio/receiving_livestream.wav"
import { NotificationContext } from '../../../contexts/NotificationContext';

function hm(sec) {
    return [(sec / 3600), ((sec % 3600) / 60)]
        .map(v => v < 10 ? "0" + parseInt(v) : parseInt(v))
        .join(":");
}

export const Livestream = forwardRef((props, ref) => {
    const notificationContext = useContext(NotificationContext);
    const playAudio = notificationContext.playAudio;
    const [selectedStreamId, setSelectedStreamId] = useState(null);

    useImperativeHandle(ref, () => ({
        setSelectedStream(livestreamId) {
            setSelectedStreamId(livestreamId)
        }
    }));

    const filteredLivestreams = props.livestreams?.filter((stream) => stream.inputUrl?.startsWith(process.env.REACT_APP_HOST));
    const selectedLiveStream = filteredLivestreams && filteredLivestreams.length !== 0 ? filteredLivestreams.find((livestream) => livestream.id === selectedStreamId) || filteredLivestreams[0] : null

    const { data, loading } = useQuery(api.GET_LIVESTREAM, {
        client: props.client,
        variables: { id: selectedLiveStream?.id },
        skip: !selectedLiveStream,
        pollInterval: 10000,
        onError(error) {
            console.error(error)
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const previousState = usePrevious(data?.livestream?.state || selectedLiveStream?.state);
    const currentState = data?.livestream?.state || selectedLiveStream?.state;
    useEffect(() => {
        if (previousState != null && currentState === "RUNNING" && previousState !== "RUNNING") playAudio(receivingLivestream);
    }, [previousState, currentState, playAudio]);

    const [cancelLivestream] = useMutation(api.CANCEL_LIVESTREAM, {
        client: props.client,
        update: api.CANCEL_LIVESTREAM_UPDATE_CACHE,
        onCompleted() {
            const message = { variant: "success", headingText: "Livestream canceled", bodyText: "You have successfully canceled your livestream.", withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        },
        onError(e) {
            const message = { variant: "danger", headingText: "Error", bodyText: e.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    })

    const cancelStream = (id) => {
        if (!window.confirm("Are you sure that you want to cancel this livestream?")) return;
        cancelLivestream({ variables: { id: id } });
    }

    const renderDropdown = (livestreams, selectedLiveStream) => {
        return <div className="dropdown me-auto" style={{ minWidth: "0px" }}>
            <button style={{ textOverflow: "ellipsis", boxShadow: "none" }} className="btn btn-link text-decoration-none px-0 dropdown-toggle text-nowrap overflow-hidden w-100" data-bs-toggle="dropdown" aria-expanded="false">
                {selectedLiveStream.name}
            </button>
            <ul className="dropdown-menu dropdown-menu">
                {livestreams.map((livestream) => <li key={livestream.id}><button onClick={() => setSelectedStreamId(livestream.id)} className="dropdown-item">{livestream.name}</button></li>)}
                <li><button className="dropdown-item" onClick={() => props.showLivestreamModal()}>+ Start livestream</button></li>
            </ul>
        </div>
    }


    const renderLivestreamState = (state) => {
        const stateMapping = {
            // TO DELETE STATES
            "CREATE_FAILED": { className: "bg-danger", label: "Error" },
            "CREATING": { className: "bg-success", label: "Preparing" },
            "DELETED": { className: "bg-warning", label: "Deleted" },
            "DELETING": { className: "bg-warning", label: "Deleting" },
            "IDLE": { className: "bg-success", label: "Scheduled" },
            "TO_PUBLISH": { className: "bg-success", label: "Scheduled" },

            // NEW STATES
            "PENDING": { className: "bg-success", label: "Scheduled" },
            "STARTING": { className: "bg-success", label: "Starting" },
            "RUNNING": { className: "bg-danger", label: "Live" },
            "RECOVERING": { className: "bg-warning", label: "Recovering" },
            "STOPPING": { className: "bg-warning", label: "Stopping" },
            "DONE": { className: "bg-success", label: "Done" },
            "CANCELED": { className: "bg-danger", label: "Canceled" }
        };

        // Override label if the livestream is actually not visible for the users.
        if (currentState !== "RUNNING") {
            stateMapping["RUNNING"].label = "Recording";
        }

        if (stateMapping[state]) {
            return <span className={`${stateMapping[state].className} badge text-white`}>{stateMapping[state].label}</span>;
        }
    }

    const renderNav = (livestreams, selectedLiveStream, actualState) => {
        if (!selectedLiveStream) return "Livestreams";

        return <>
            {renderDropdown(livestreams, selectedLiveStream, actualState)}
            {renderLivestreamState(actualState)}
        </>
    }

    const renderStartsSoon = (stream) => {
        if (currentState !== "RUNNING" && moment(stream.startsAt).isBefore(moment().add(5, 'minutes'))) {
            const timeLeft = Math.max(Math.round(moment(stream.startsAt).diff(moment(), 'minutes', true)), 0);

            return (
                <div className="alert alert-primary border-0 border-bottom p-3 d-flex m-0 ">
                    <InfoIcon width="16" height="16" className="me-2 flex-shrink-0 mt-1" />
                    <small>Great - your livestream will be ready in {timeLeft + 1} minute{(timeLeft + 1) > 1 ? "s" : ""}. Wait for the phases "Scheduled, Starting, Recording, Live". As soon as it is ON AIR (phase recording) with the red frame around the canvas and you hear the voice "You are sending a livestream" the recording has started, from then on everything will be shown in the stream. As soon as the metrics show up (phase live), the button "Preview and share" gets a red frame and you hear the voice "Your audience is receiving your livestream", your livestream is visible for your audience. The startup behavior may deviate by a few seconds. Have a great show!</small>
                </div>
            );
        }
    }

    const renderMetrics = (metrics) => {
        if (!metrics && loading) {
            return <div className="d-flex flex-column align-items-center justify-content-center p-3 text-center">
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-2 text-muted">Metrics are loading</p>
            </div>
        }

        if (!metrics || (metrics.ccUsers === -1 && metrics.maxCcUsers === -1 && metrics.views === -1)) return;

        return (
            <div className="border-bottom p-3">
                <h6>Metrics</h6>
                <div className='row g-2'>
                    {metrics.ccUsers > -1 && <div className="col-4 p-2 text-center fw-bold">
                        <div className="fs-4">{metrics.ccUsers}</div>
                        <span className="text-muted">Live</span>
                    </div>}
                    {metrics.maxCcUsers > -1 && <div className="col-4 p-2 text-center">
                        <div className="fs-4">{metrics.maxCcUsers}</div>
                        <span className="text-muted">Maximum</span>
                    </div>}
                    {metrics.views > -1 && < div className="col-4 p-2 text-center">
                        <div className="fs-4">{metrics.views}</div>
                        <span className="text-muted">Views</span>
                    </div>}
                </div>
            </div>
        )
    }

    const renderStreamSettings = (stream) => {
        return (
            <div className="p-3">
                <ul className="list-unstyled mb-3">
                    <li><b>Name:</b> {stream.name}</li>
                    <li><b>Starts at:</b> {moment(stream.startsAt).format('MMMM Do YYYY, h:mm a')}</li>
                    {currentState === "RUNNING" && <li><b>Time left:</b> {hm(Math.max(0, moment(stream.startsAt).add(stream.duration, "seconds").diff(moment(), 'seconds')))}</li>}
                    {currentState !== "RUNNING" && <li ><b>Duration:</b> {hm(stream.duration)}</li>}
                    <li><b>Max users:</b> {stream.expectedVisitors}</li>
                    <li><b>Chat:</b> {stream.chatEnabled ? "Enabled" : "Disabled"}</li>
                </ul>
                <div className="d-grid gap-2">
                    <button onClick={() => props.showShareVideoModal(stream)} className={`btn btn-dark ${currentState === "RUNNING" ? "btn-danger-flashing" : null}`}>Preview &amp; Share</button>
                    {currentState === 'RUNNING' && <button onClick={() => props.showLivestreamModal(stream)} className="btn btn-primary">Extend</button>}
                    {(currentState !== 'DONE' && stream.canceledAt == null) && <button className="btn" onClick={() => cancelStream(stream.id)}>Cancel stream</button>}
                </div>
            </div >
        );
    }

    const renderBody = (stream, metrics) => {
        if (!stream) {
            return (
                <Center>
                    <h3>You don't have any livestreams!</h3>
                    <p className="text-muted">Create your first livestream</p>
                    <button className="btn btn-primary" onClick={() => props.showLivestreamModal()}>Start livestream</button>
                </Center>
            );
        }

        return <>
            {renderStartsSoon(stream)}
            {renderMetrics(metrics)}
            {renderStreamSettings(stream)}
        </>;
    }

    if (props.loading) {
        return <>
            <div className="sidebar-content-header">
                <span>Livestreams</span>
            </div>
            <div className="sidebar-content-body">
                <Center>
                    <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2 text-muted">Livestreams are loading</p>
                </Center>
            </div>
        </>;
    } else if (props.error) {
        console.error(props.error);

        return <>
            <div className="sidebar-content-header">
                <span>Livestreams</span>
            </div>
            <div className="sidebar-content-body">
                <Center>
                    <h3>Oops, this did not work!</h3>
                    <p className="text-muted">There was an error loading your livestreams.</p>
                    <div className="btn btn-primary" onClick={() => props.refetch()}>Try Again</div>
                </Center>
            </div>
        </>;
    }

    return <>
        <div className='sidebar-content-header justify-content-between'>
            {renderNav(filteredLivestreams, selectedLiveStream, data?.livestream?.actualState)}
        </div>
        <div className='sidebar-content-body'>
            {renderBody(selectedLiveStream, data?.livestream.metrics)}
        </div>
    </>
})

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
