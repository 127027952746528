import { useImperativeHandle, useRef, forwardRef, useContext } from 'react';
import Canvas from './studio/Canvas';
import Sources from './studio/Sources';
import ViewStyle from './studio/ViewStyle';
import { Sidebar } from './studio/Sidebar';
import StreamControl, { InviteModal } from './studio/StreamControl';
import { RtcContext } from '../contexts/RtcContext';
import { ShareVideoModal } from '../modals/ShareVideoModal';
import { StartStreamModal } from './studio/sidebar/StreamIns';

export default forwardRef(({ room, client, adminClient, showLivestreamModal }, ref) => {
    const rtcContext = useContext(RtcContext);
    const sidebarRef = useRef(null);
    const modalShareVideoRef = useRef(null);
    const startStreamModalRef = useRef(null);
    const inviteModalRef = useRef(null);

    useImperativeHandle(ref, () => ({
        onSuccessPayment(livestreamId) {
            sidebarRef.current?.setLivestreamActive(livestreamId)
        }
    }))

    return (
        <>
            {/* Modals are here because the sidebar has position: fixed */}
            <ShareVideoModal ref={modalShareVideoRef} />
            <StartStreamModal ref={startStreamModalRef} />
            <InviteModal ref={inviteModalRef} />

            <div className="flex-fill d-flex" style={{ position: "fixed", top: 0, left: 0, bottom: 0, right: 0, paddingTop: "58px" }}>
                <div className="flex-fill vt-scrollbar" style={{ maxHeight: "100%", overflowY: "auto" }}>
                    <div className="mb-3" style={{ backgroundColor: "#6f6f6f" }}>
                        <div style={{ maxWidth: "1280px", margin: "0 auto" }}>
                            <Canvas room={room} />
                        </div>
                    </div>
                    {rtcContext.user.isAdmin && <ViewStyle />}
                    <Sources />
                    <StreamControl inviteModalRef={inviteModalRef} />
                </div>

                <Sidebar
                    ref={sidebarRef}
                    room={room}
                    client={client}
                    adminClient={adminClient}
                    startStreamModalRef={startStreamModalRef}
                    showLivestreamModal={showLivestreamModal}
                    showShareVideoModal={(stream) => modalShareVideoRef.current?.showModal(stream)} />
            </div>
        </>
    );
});
