export default function Basket({ children }) {
    const renderTitle = (title, price, invertPrice = false) => {
        return <div className="d-flex flex-row justify-content-between px-4">
            <b className="me-3">{title}</b>
            {formatPrice(price, invertPrice)}
        </div>;
    }

    const renderItem = (item, index) => {
        return (
            <li key={index} className="mb-3">
                {renderTitle(item.name, item.netAmount)}
                {item.details && <ul className="list-unstyled text-muted small px-4">
                    {item.details.map((d, i) => <li key={i}>{d}</li>)}
                </ul>}
            </li>
        );
    }

    const renderDiscount = (item, index) => {
        return (
            <li key={index} className="mb-3">
                {renderTitle(item.name, item.amount, true)}
            </li>
        );
    }

    const taxPercent = Math.round(children.taxAmount.amount / children.netAmount.amount * 100)
    const oldTax = children.oldPayment?.taxAmount;
    const hasOldTax = oldTax != null && oldTax > 0;

    return <>
        <ul className="list-unstyled">
            {children.items.map(renderItem)}
            {children.discounts.map(renderDiscount)}
        </ul>

        <hr />

        <ul className="list-unstyled">
            <li className="mb-3">
                {renderTitle("Subtotal", children.totalAmount)}
                {children.taxAmount.amount > 0 && <ul className="list-unstyled text-muted small px-4">
                    <li>Additional Tax: {formatPrice(children.taxAmount)}</li>
                    {hasOldTax && <li>Tax basket items: {formatPrice(addMoney(children.taxAmount, oldTax))}</li>}
                    {hasOldTax && <li>Tax old payment: {formatPrice(oldTax, true)}</li>}
                </ul>}
            </li>
        </ul>


        {children.oldPayment && children.oldPayment.netAmount > 0 && renderTitle("Old payment", children.oldPayment.netAmount, true)}
        {children.fromWallet && children.fromWallet.amount > 0 && renderTitle("From wallet", children.fromWallet, true)}
        {children.toPay.amount < 0 && renderTitle("To wallet", children.toPay, true)}

        <hr />

        {renderTitle(
            <>
                Total to pay <small className="text-muted">({taxPercent > 0 ? `${taxPercent}% tax included` : `VAT free purchase`})</small>
            </>,
            children.toPay.amount < 0 ? { ...children.toPay, amount: 0 } : children.toPay
        )}
    </>;
}

const formatPrice = ({ amount, currency }, invertPrice) => {
    if (invertPrice) amount = amount * -1;

    return Intl.NumberFormat('de-DE', { style: 'currency', currency: currency })
        .format(amount / 100);
}

const addMoney = ({ amount1, currency }, { amount2 }) => {
    return {
        amount: amount1 + amount2,
        currency
    };
}
