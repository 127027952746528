import React, { Component, createContext } from 'react'
import JoiningScreen from '../components/general/JoiningScreen'
import { NotificationContext } from './NotificationContext'
import { createRTCMediaStream, isStreamIn, isRecorder, getBestResolution, withTimeout, sleep } from '../helper/Helper';
import * as api from "../api"
import knock from "../assets/audio/knock.wav"
import live from "../assets/audio/sending_livestream.wav"
import { Socket } from "phoenix"
import * as Owt from "owt-client-javascript/src/sdk/export";
import debounce from 'lodash.debounce';

export const RtcContext = createContext();

const socketUrl = new URL("/socket", process.env.REACT_APP_STUDIO_HOST.replace("http://", "ws://").replace("https://", "wss://")).toString();
const preferLocalStream = true;

export default class RtcContextProvider extends Component {
    static contextType = NotificationContext;

    constructor(props) {
        super(props);

        this.state = {
            roomName: props.roomName,
            token: props.token,
            serviceToken: props.serviceToken,
            serviceUrl: props.serviceUrl,
            isRecorder: props.isRecorder,

            // joining
            joinStep: "START_ROOM",
            joinError: null,

            // conference state
            streams: [],
            users: [],
            visibleParticipants: [],
            visibleStream: null,
            volumes: {},

            user: {
                id: null,
                name: null,
                isAdmin: false,
            },
            layout: "solo",
            version: -1,
            roomLimit: -1,

            changeMediaDevice: this.changeMediaDevice.bind(this),
            updateLayout: this.updateLayout.bind(this),

            shareScreen: this.shareScreen.bind(this),

            getVolume: this.getVolume.bind(this),
            setVolume: this.setVolume.bind(this),
            muteAudio: this.muteAudio.bind(this),
            muteVideo: this.muteVideo.bind(this),
            isVideoMuted: this.isVideoMuted.bind(this),
            isAudioMuted: this.isAudioMuted.bind(this),

            isRecording: this.isRecording.bind(this),

            addParticipant: this.addParticipant.bind(this),
            removeParticipant: this.removeParticipant.bind(this),
            setVisibleStream: this.setVisibleStream.bind(this),
            swapParticipant: this.swapParticipant.bind(this),
        }

        this.socket = null;
        this.channel = null;
        this.conference = null;

        this.publication = null;
        this.mediaStream = null;
        this.screenShareStreams = {};

        this.joinWithTurn = false;
        this.onSocketUpdate = this.onSocketUpdate.bind(this);
        this.leaveConference = this.leaveConference.bind(this);
        this.onStreamAdded = this.onStreamAdded.bind(this);
        this.onParticipantJoined = this.onParticipantJoined.bind(this);
    }

    componentDidMount() {
        window.addEventListener("pagehide", this.leaveConference); //for safari mobile
        window.addEventListener("beforeunload", this.leaveConference);

        this.setup();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.user || this.state.user.isAdmin) return;

        const wasVisible = prevState.visibleParticipants.includes(this.state.user.id);
        const isVisible = this.state.visibleParticipants.includes(this.state.user.id);

        if (isVisible && !wasVisible) {
            this.context.info("You're in the conference!", "Everyone can see and hear you");
        } else if (wasVisible && !isVisible) {
            this.context.info("You're backstage!", "Only the host can see you. The host may add you to the broadcast at any time. Be ready!");
        }

        if (this.publication != null) {
            const wasMuted = prevState.volumes[this.publication.id] === 0;
            const isMuted = this.state.volumes[this.publication.id] === 0;

            if (isMuted && !wasMuted) {
                this.context.error("Microphone was muted", "Microphone was muted by admin");
            } else if (wasMuted && !isMuted) {
                this.context.info("Microphone was unmuted", "Microphone was unmuted by admin");
            }
        }
    }

    render() {
        if (this.state.joinStep) {
            if (this.props.isRecorder) return null;

            let title;
            let displayErrors = true;
            switch (this.state.joinStep) {
                case "START_ROOM":
                    title = "Starting the conference";
                    break;
                case "JOIN_SOCKET":
                    title = "Loading the current state";
                    break;
                case "JOIN_ROOM":
                    if (this.state.joinError?.graphQLErrors?.some((e) => e.message === "max_participants_limit_reached")) {
                        displayErrors = false;
                        title = "The room is currently full. You will automatically join as soon as there is some space left.";
                    } else if (this.state.joinError?.graphQLErrors?.some((e) => e.message === "not_found")) {
                        displayErrors = false;
                        title = "The organisator is not in the conference. You will be redirected as soon as the event starts.";
                    } else {
                        title = "Checking if the conference is up";
                    }
                    break;
                case "JOIN_CONFERENCE":
                    title = "Entering the conference";
                    break;
                case "SEND_VIDEO":
                    title = "Publishing your video";
                    break;
                case "INITIALIZE":
                    title = "Almost ready…";
                    break;
                default:
                    throw new Error("State not implemented");
            }

            const onCancel = this.state.joinError != null ? () => {
                sessionStorage.removeItem("userName")
                window.location.reload()
            } : null;

            if (this.state.joinError != null && displayErrors) {
                return <JoiningScreen
                    spin={false}
                    title={title}
                    error={this.state.joinError}
                    onRetry={() => window.location.reload()}
                    onCancel={onCancel} />;
            }

            return <JoiningScreen spin={displayErrors} title={title} onCancel={onCancel} />;
        }

        return (
            <RtcContext.Provider value={this.state} >
                {this.props.children}
            </RtcContext.Provider>
        );
    }

    componentWillUnmount() {
        window.removeEventListener("pagehide", this.leaveConference); //for safari mobile
        window.removeEventListener("beforeunload", this.leaveConference);

        this.socket?.disconnect();
        this.leaveConference();
    }

    get useSimulcast() {
        return false;
        // return navigator.userAgent.toLowerCase().indexOf('firefox') === -1;
    }

    async setup() {
        const steps = [
            { step: "START_ROOM", fn: this.createService },
            { step: "JOIN_ROOM", fn: this.joinRoom },
            { step: "JOIN_CONFERENCE", fn: this.joinConference },
            { step: "JOIN_SOCKET", fn: this.joinSocket },
            { step: "SEND_VIDEO", fn: this.sendInitialVideo },
            { step: "INITIALIZE", fn: this.initializeState },
        ];

        let lastResponse = null;
        let currentStep = steps[0].step;
        while (true) {
            const curStep = currentStep;
            const stepIdx = steps.findIndex(({ step }) => step === curStep);
            const step = steps[stepIdx];

            console.log("[RTCContext] setup", step.step, `- joinWithTurn: ${this.joinWithTurn}`);

            this.setState({ joinStep: currentStep, joinError: null });

            try {
                const resp = await step.fn.call(this, lastResponse);
                const respStep = steps.find(function ({ step }) { return resp === step });

                if (respStep != null) {
                    currentStep = respStep.step;
                } else if (steps[stepIdx + 1] != null) {
                    lastResponse = resp;
                    currentStep = steps[stepIdx + 1].step;
                } else {
                    break;
                }
            } catch (error) {
                this.setState({ joinError: error });
                break;
            }
        }
    }

    async createService() {
        if (!this.props.token) return null;
        return await this.props.client.mutate({ mutation: api.START_ROOM });
    }

    async joinRoom() {
        while (true) {
            const response = await this.props.client.mutate({
                mutation: api.JOIN_ROOM,
                variables: {
                    name: this.props.userName,
                    roomId: this.props.roomName,
                    role: this.props.isRecorder ? "viewer" : "presenter"
                }
            }).then(({ data }) => {
                this.setState({ roomLimit: data.joinRoom.limit });
                return data.joinRoom.token;
            }).catch((error) => {
                this.setState({ joinError: error });
                return null;
            });

            if (response != null) {
                return response;
            } else {
                await sleep(5000);
            }
        }
    }

    async joinSocket() {
        if (this.socket != null) return;

        this.socket = new Socket(socketUrl);
        this.channel = this.socket.channel(`conference:${this.props.roomName}`, { token: this.props.token });
        this.channel.on("update", this.onSocketUpdate);

        try {
            await new Promise((resolve, reject) => {
                this.socket.onOpen(resolve);
                this.socket.onError(() => {
                    this.socket.disconnect();
                    this.socket = null;
                    reject(new Error("Cannot join the socket."));
                });
                this.socket.connect();
            });
        } catch (error) {
            if (this.state.version > -1) {
                await sleep(5000);
                return this.joinSocket();
            } else {
                throw error;
            }
        }

        // After the initial join we add an error handler to handle reconnects.
        this.socket.onError(async () => {
            if (this.state.user.isAdmin) {
                const message = { variant: "danger", headingText: "Lost admin connection to room", bodyText: "You cannot perform any actions (change layout, add participants, …) until we have reconnected. We try to restore the connection in the background.", withTimer: true, timer: 10000 }
                this.context.showMessage(message);
            }

            await sleep(5000);
            this.joinSocket();
        });

        const state = await new Promise((resolve, reject) => {
            this.channel.join()
                .receive("ok", resolve)
                .receive("error", reject)
                .receive("timeout", reject)
        });

        if (this.state.version > -1 && this.state.user.isAdmin) {
            const message = { variant: "success", headingText: "Connection to room recovered", bodyText: "Everything works fine again.", withTimer: true, timer: 5000 }
            this.context.showMessage(message);
        }

        this.onSocketUpdate(state);
    }

    async joinConference(token) {
        if (this.conference) {
            try {
                await withTimeout(this.conference.leave(), 5000);
            } catch (e) {
                console.warn("Cannot leave old onference", e);
            }
        }

        const iceServers = [
            { urls: "stun:stun.video.taxi" }
        ];

        if (this.joinWithTurn) {
            iceServers.push({
                urls: [
                    "turn:stun.video.taxi?transport=udp",
                    "turn:stun.video.taxi?transport=tcp"
                ],
                credential: "2Ygn@u9JjTXoCq",
                username: "videotaxi"
            })
        }

        this.conference = new Owt.Conference.ConferenceClient({ rtcConfiguration: { iceServers } });
        this.conference.addEventListener("serverdisconnected", (message) => {
            console.warn(message);

            // Do not reload while we are reconnecting
            if (this.state.joinStep == null) {
                window.location.reload();
            }
        });

        try {
            const response = await this.conference.join(token);

            this.setState({
                user: {
                    id: response.self.id,
                    name: this.props.userName,
                    isAdmin: this.props.token != null
                }
            });
        } catch (error) {
            console.error(error);
            if (this.joinWithTurn) throw error;

            this.joinWithTurn = true;
            return this.joinConference(token);
        }
    }

    async sendInitialVideo() {
        if (this.props.isRecorder) return true;

        try {
            return await this.publishVideo(this.props.settingsContext.selectedVideoId, this.props.settingsContext.selectedAudioId, this.props.settingsContext.useHd);
        } catch (error) {
            if (this.joinWithTurn) throw error;

            // Return to previous step
            this.joinWithTurn = true;
            return "JOIN_ROOM";
        }
    }

    async initializeState() {
        const info = this.conference.info;

        info.remoteStreams.forEach(this.onStreamAdded);
        this.conference.addEventListener("streamadded", (event) => this.onStreamAdded(event.stream));

        info.participants.forEach(this.onParticipantJoined);
        this.conference.addEventListener("participantjoined", (event) => this.onParticipantJoined(event.participant, true));

        this.setState({
            users: info.participants,
            joinStep: null
        });
    }

    onStreamAdded(stream) {
        console.log("[RtcContext] onStreamAdded", stream);

        if (preferLocalStream && stream.id === this.publication?.id) {
            stream.mediaStream = this.mediaStream
            this.handleStream(stream)
        } else if (preferLocalStream && this.screenShareStreams[stream.id]) {
            stream.mediaStream = this.screenShareStreams[stream.id]
            this.handleStream(stream)
        } else {
            this.subscribeToVideo(
                stream,
                this.conference
            );
        }
    }

    onSocketUpdate(state, updateState = true) {
        console.log("[RtcContext] onSocketUpdate", state, updateState);

        if (updateState !== false) {
            this.setState({
                version: state.version,
                visibleParticipants: state.visible_participants,
                visibleStream: state.visible_stream,
                volumes: state.volume,
                layout: state.layout
            });
        } else {
            this.setState({ version: state.version });
        }
    }

    onParticipantJoined(participant, isNew) {
        console.log("[RtcContext] onParticipantJoined", participant);

        // The user has just joined the conference for the first time.
        if (isNew === true) {
            if (this.state.user.isAdmin) {
                const file = isRecorder(participant) ? live : knock;
                this.context.playAudio(file);
            }
            this.setState({ users: this.state.users.concat([participant]) });
        }

        participant.addEventListener("left", () => {
            console.log("[RtcContext] onParticipantLeft", participant);

            this.setState({
                users: this.state.users.filter((u) => u.id !== participant.id),
                visibleParticipants: this.state.visibleParticipants.filter((pId) => pId !== participant.id)
            });
        });
    }

    addParticipant(participantId, promote = false) {
        if (this.state.visibleParticipants.includes(participantId) && !promote) return;

        const visibleParticipants = this.state.visibleParticipants.filter((pId) => pId !== participantId);
        if (promote === true) {
            visibleParticipants.unshift(participantId);
        } else {
            visibleParticipants.push(participantId);
        }

        this.updateVisibleParticipants(visibleParticipants);
    }

    removeParticipant(participantId) {
        const visibleParticipants = this.state.visibleParticipants.filter((pId) => pId !== participantId);
        this.updateVisibleParticipants(visibleParticipants);
    }

    swapParticipant(p1Id, p2Id) {
        const idx1 = this.state.visibleParticipants.indexOf(p1Id);
        const idx2 = this.state.visibleParticipants.indexOf(p2Id);
        if (idx1 < 0 || idx2 < 0) return;

        const visibleParticipants = [...this.state.visibleParticipants];
        visibleParticipants[idx1] = p2Id;
        visibleParticipants[idx2] = p1Id;

        this.updateVisibleParticipants(visibleParticipants);
    }

    updateVisibleParticipants(visibleParticipants) {
        // Remove participants that are not known to the admin. Otherwise they'll remain in the socket until the room gets closed.
        visibleParticipants = visibleParticipants.filter((p) => this.state.users.some((u) => u.id === p));

        this.setState(
            { visibleParticipants },
            this.sendUpdate.bind(this, "update_visible_participants", { "visible_participants": visibleParticipants })
        );
    }

    setVisibleStream(visibleStream) {
        // Automatically choose the best suited layout when switching screen
        const prevLayout = this.state.layout;
        let layout = this.state.layout;
        if (visibleStream != null && this.state.layout.indexOf("screen") === -1) {
            if (this.state.visibleParticipants.length === 0) {
                layout = "screen";
            } else if (this.state.visibleParticipants.length === 1) {
                layout = "solo-screen";
            } else {
                layout = "users-screen";
            }
        }

        this.setState(
            { visibleStream, layout },
            async () => {
                if (layout !== prevLayout) {
                    await this.sendUpdate("update_layout", { "layout": layout }, false).catch((error) => null);
                }
                this.sendUpdate("update_visible_stream", { "visible_stream": visibleStream });
            },
        );
    }

    updateLayout(layout) {
        this.setState(
            { layout },
            this.sendUpdate.bind(this, "update_layout", { "layout": layout })
        );
    }

    leaveConference() {
        this.conference?.leave();
    }

    async changeMediaDevice(videoId, audioId, useHd) {
        if (this.publication) this.publication.stop();

        // TODO: Handle errors/retries...
        try {
            await this.publishVideo(videoId, audioId, useHd);
        } catch (e) {
            console.error(e)
        }
    }

    isRecording() {
        return this.state.users.some(isRecorder);
    }

    subscribeToVideo(stream) {
        let videoOptions = undefined;
        if (isStreamIn(stream)) {
            const inputRes = stream.settings.video[0].resolution;
            const originalRatio = (inputRes.width / inputRes.height).toFixed(3);
            const resolutions = stream.extraCapabilities.video.resolutions.filter((r) => {
                const aspectRatio = (r.width / r.height).toFixed(3)
                return originalRatio === aspectRatio;
            });

            const resolution = getBestResolution(resolutions, 720);

            if (resolution != null) {
                videoOptions = { resolution };
            }
        } else if (this.useSimulcast) {
            videoOptions = { rid: "h" }
        }

        const subscribeOptions = {
            audio: stream.settings.audio.length > 0,
            video: videoOptions
        };

        this.conference.subscribe(stream, subscribeOptions).then((subscription) => {
            console.log(subscription);
            stream.mediaStream = subscription.stream;
            this.handleStream(stream)
        });
    }

    handleStream(stream) {
        stream.addEventListener("ended", () => {
            console.log("[RtcContext] onStreamEnded", stream);

            this.setState({
                streams: this.state.streams.filter((s) => s.id !== stream.id),
                visibleStream: this.state.visibleStream === stream.id ? null : this.state.visibleStream
            });
        });

        this.setState({ streams: [...this.state.streams, stream] });
    }

    sendUpdate(type, data, updateState = true) {
        return new Promise((resolve, reject) => {
            this.channel.push(type, { ...data, version: this.state.version })
                .receive("ok", (data) => {
                    this.onSocketUpdate(data, updateState);
                    resolve(data);
                })
                .receive("error", (error) => {
                    console.error("[RTCContext] sendUpdate error", error);
                    reject(error);
                })
                .receive("timeout", (error) => {
                    console.error("[RTCContext] sendUpdate timeout", error);
                    reject(error);
                });
        });
    }

    async publishVideo(videoId, audioId, useHd) {
        // Create a new RTC MediaStream
        const stream = await createRTCMediaStream(videoId, audioId, useHd);

        // Publish to OWT
        const codecs = ['vp8', 'h264'];
        const localStream = new Owt.Base.LocalStream(
            stream,
            new Owt.Base.StreamSourceInfo(Owt.Base.AudioSourceInfo.MIC, Owt.Base.VideoSourceInfo.CAMERA)
        );

        let options = undefined;
        if (this.useSimulcast) {
            options = {
                video: [
                    { rid: 'l', active: true, scaleResolutionDownBy: 4.0 },
                    { rid: 'h', active: true, scaleResolutionDownBy: 1.0 }
                ]
            };
        }

        this.publication = await withTimeout(this.conference.publish(localStream, options, codecs), 15000);

        // Setup local state
        this.mediaStream = stream;
        this.setState({});

        return this.publication;
    }

    getVolume(streamId) {
        const volume = this.state.volumes[streamId];
        return volume != null ? volume : 1;
    }

    setVolume(streamId, volume) {
        console.log("[RTCContext] setVolume", streamId, volume)
        volume = parseFloat(volume);

        this.setState({ volumes: { ...this.state.volumes, [streamId]: volume } });
        this.publishVolumeChange(streamId, volume);
    }

    publishVolumeChange = debounce((streamId, volume) => {
        this.sendUpdate("set_volume", { "stream_id": streamId, "volume": volume });
    }, 500);

    muteVideo(shouldMute) {
        if (this.mediaStream == null) return;
        this.mediaStream.getVideoTracks().forEach((t) => t.enabled = !shouldMute);
        this.setState({});
    }

    isVideoMuted() {
        if (this.mediaStream == null) return false;
        return this.mediaStream.getVideoTracks().some((track) => track.enabled === false);
    }

    muteAudio(shouldMute) {
        if (this.mediaStream == null) return;
        this.mediaStream.getAudioTracks().forEach((t) => t.enabled = !shouldMute);
        this.setState({});
    }

    isAudioMuted() {
        if (this.mediaStream == null) return false;
        return this.mediaStream.getAudioTracks().some((track) => track.enabled === false);
    }

    async shareScreen() {
        // Create MediaStream
        const audioConstraints = new Owt.Base.AudioTrackConstraints(Owt.Base.AudioSourceInfo.SCREENCAST);
        const videoConstraints = new Owt.Base.VideoTrackConstraints(Owt.Base.VideoSourceInfo.SCREENCAST);
        videoConstraints.frameRate = 30;
        videoConstraints.resolution = { width: 1280, height: 720 };
        const stream = await Owt.Base.MediaStreamFactory.createMediaStream(
            new Owt.Base.StreamConstraints(
                audioConstraints,
                videoConstraints
            )
        );

        // Publish to OWT
        const codecs = ['vp8'];
        const localStream = new Owt.Base.LocalStream(
            stream,
            new Owt.Base.StreamSourceInfo(Owt.Base.AudioSourceInfo.SCREENCAST, Owt.Base.VideoSourceInfo.SCREENCAST)
        );
        const publication = await withTimeout(this.conference.publish(localStream, undefined, codecs), 15000);

        // Setup local state
        this.screenShareStreams[publication.id] = stream;
        this.setState({});

        publication.addEventListener("ended", () => {
            delete this.screenShareStreams[publication.id];
        });
        publication.addEventListener("error", (error) => {
            console.error("[RtcContext] Error on screen share publication", error);
            delete this.screenShareStreams[publication.id];
        });
        stream.getVideoTracks()[0].onended = publication.stop;

        // TODO: Check permission or other errors
        // if (e.name !== 'NotAllowedError')
        //     reject(e.message);

        return this.publication;
    }
}
