import edgeUrl from "../../assets/browsers/edge.png";
import firefoxUrl from "../../assets/browsers/firefox.svg";
import chromeUrl from "../../assets/browsers/chrome.svg";
import operaUrl from "../../assets/browsers/opera.svg";
import { detect } from "detect-browser";

export default function RequireBrowser({ children }) {
    const browser = detect();

    let supported = false;
    if (browser) {
        const version = parseInt(browser.version.split(".")[0]);

        supported = (
            (browser.name == "firefox" && version > 72) ||
            (browser.name == "opera" && version > 66) ||
            (["chrome", "edge", "edge-chromium", "chromium-webview"].indexOf(browser.name) > -1 && version > 80)
        );
    }
    if (supported) {
        return children;
    } else {
        return <div className="w-100 d-flex p-3 flex-column align-items-center justify-content-center">
            <h2 className="text-center">Your browser is not supported.</h2>
            <p className="text-center" style={{ maxWidth: "700px" }}>We recommend to use one of the browsers listed below. If you are currently using one of these browsers, make sure that the latest updates are installed.</p>

            <div className="d-flex w-100 flex-row align-items-center justify-content-center my-5">
                <div style={{ flex: 1, maxWidth: "100px" }} className="me-4">
                    <img src={chromeUrl} style={{ width: "100%" }} />
                    <p class="mt-2 mb-0 text-center">Chrome</p>
                </div>
                <div style={{ flex: 1, maxWidth: "100px" }} className="me-4">
                    <img src={firefoxUrl} style={{ width: "100%" }} />
                    <p class="mt-2 mb-0 text-center">Firefox</p>
                </div>
                <div style={{ flex: 1, maxWidth: "100px" }} className="me-4">
                    <img src={operaUrl} style={{ width: "100%" }} />
                    <p class="mt-2 mb-0 text-center">Opera</p>
                </div>
                <div style={{ flex: 1, maxWidth: "100px" }}>
                    <img src={edgeUrl} style={{ width: "100%" }} />
                    <p class="mt-2 mb-0 text-center">Edge</p>
                </div>
            </div>

            {browser && <small class="text-muted">Current browser: {browser.name[0].toUpperCase() + browser.name.substring(1)} {browser.version}</small>}
        </div>;
    }
}
