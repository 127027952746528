import React, { useContext, useRef, useState } from 'react';
import DeviceConfig from '../../general/DeviceConfig';
import { RtcContext } from '../../../contexts/RtcContext';
import { SettingsContext } from '../../../contexts/SettingsContext';

export default function Settings() {
    const [disabled, setDisabled] = useState(true);
    const [audioId, setAudioId] = useState();
    const [videoId, setVideoId] = useState();
    const [useHd, setUseHd] = useState();
    const deviceConfigRef = useRef(null);
    const rtcContext = useContext(RtcContext);
    const settingsContext = useContext(SettingsContext)

    const _onSaveMediaDevices = (audioId, videoId, useHd) => {
        setAudioId(audioId)
        setVideoId(videoId)
        setUseHd(useHd)
    }

    const _onSaveSettings = () => {
        const isMobileSafari = (/iPhone|iPad|iPod/i.test(navigator.userAgent))
        if (!isMobileSafari) deviceConfigRef.current.stopVideo()
        settingsContext.setSelectedVideoInput(videoId)
        settingsContext.setSelectedAudioInput(audioId)
        settingsContext.setUseHd(useHd)
        rtcContext.changeMediaDevice(videoId, audioId, useHd)
        if (!isMobileSafari) deviceConfigRef.current.enableVideo(videoId, audioId, useHd)
    }

    return <>
        <Navigation disabled={disabled} saveSettings={_onSaveSettings} />
        <div className="sidebar-content-body p-3">
            <DeviceConfig ref={deviceConfigRef} inSettings={true} saveMediaDevices={_onSaveMediaDevices} setDisabled={setDisabled} />
        </div>
    </>
}

function Navigation({ disabled, saveSettings }) {
    const context = useContext(RtcContext);

    const isLoaded = context.streams.some((s) => s && s.origin === context.user.id && s.mediaStream !== "placeholder")

    return <div className="sidebar-content-header">
        <div>Audio &amp; Video</div>
        <button className="btn btn-primary btn-sm ms-auto" disabled={disabled || !isLoaded} onClick={saveSettings}>Update</button>
    </div>
}
