const _toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};

export const getErrors = (errors, path) => {
    if (errors == null) return [];

    const split = path.split(/\[(.+?)\]/).filter((s) => s !== "");
    return errors.filter((e) => JSON.stringify(e.path.map(_toCamel)) === JSON.stringify(split)).map((e) => e.message);
}

export const hasError = (errors, path) => getErrors(errors, path).length > 0;

export const globalErrors = (errors, excludedFields = []) => {
    if (errors == null) return null;

    const unassignedMessages = errors.filter((e) => {
        return e.path == null || e.path.length === 0 || !excludedFields.includes(_toCamel(e.path[0]));
    })

    if (unassignedMessages.length === 0) return null;

    return (
        <p className="alert alert-danger">{unassignedMessages.map((m) => m.message).join(". ")}</p>
    );
}
