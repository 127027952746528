import React, { useContext, useRef, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import * as api from "../../../api";
import TextareaAutosize from 'react-textarea-autosize';
import { Center } from '../Sidebar';
import { NotificationContext } from '../../../contexts/NotificationContext';

import { ReactComponent as TickerImg } from '../../../assets/img/icons/ticker.svg';
import { ReactComponent as StaticImg } from '../../../assets/img/icons/static.svg';
import { ReactComponent as DeleteImg } from '../../../assets/img/icons/delete.svg';
import { ReactComponent as OkImg } from '../../../assets/img/icons/ok.svg';

export default function Banner({ room, client }) {
    const [showAddBanner, setShowAddBanner] = useState(false);
    const [currentEditingId, setEditingId] = useState(null);
    const contentRef = useRef(null);

    const { loading, error, data, refetch } = useQuery(api.GET_BANNERS, {
        client: client,
        notifyOnNetworkStatusChange: true
    });

    const renderBanner = (banner) => {
        return <BannerItem
            client={client}
            key={banner.id}
            banner={banner}
            isEditing={currentEditingId === banner.id}
            startEditing={() => currentEditingId === null ? setEditingId(banner.id) : null}
            stopEditing={() => setEditingId(null)}
            room={room} />;
    }

    const scrollToBottom = () => {
        if (contentRef === null || contentRef.current === null) return;
        contentRef.current.scrollTo(0, contentRef.current.scrollHeight)
    }

    const onBannerCreated = () => {
        setShowAddBanner(false);
        scrollToBottom();
    }

    if (loading) {
        return <>
            <div className="sidebar-content-header">
                <span>Banners</span>
            </div>
            <div className="sidebar-content-body">
                <Center>
                    <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2 text-muted">Banners are loading</p>
                </Center>
            </div>
        </>;
    } else if (error) {
        return <>
            <div className="sidebar-content-header">
                <span>Banners</span>
            </div>
            <div className="sidebar-content-body">
                <Center>
                    <h3>Oops, this did not work!</h3>
                    <p className="text-muted">There was an error loading your banners.</p>
                    <div className="btn btn-primary" onClick={() => refetch()}>Try Again</div>
                </Center>
            </div>
        </>;
    } else if (showAddBanner) {
        return <AddBannerForm onCreated={onBannerCreated} onCancel={() => setShowAddBanner(false)} client={client} />;
    } else if (data.banners.length === 0) {
        return <>
            <div className="sidebar-content-header">
                <span>Banners</span>
            </div>
            <Center>
                <h3>You don't have any banners!</h3>
                <p className="text-muted">Create your first banner</p>
                <button className="btn btn-primary" onClick={() => setShowAddBanner(true)}>Create banner</button>
            </Center>
        </>;
    } else {
        return <>
            <div className="sidebar-content-header">
                <span>Banners</span>
                <button className="btn btn-sm btn-primary ms-auto" onClick={() => setShowAddBanner(true)}>Add banner</button>
            </div>
            <div className="sidebar-content-body p-3" ref={contentRef}>
                {data.banners.map(renderBanner)}
            </div>
        </>;

    }
}

function BannerItem({ banner, room, isEditing, startEditing, stopEditing, client }) {
    const notificationContext = useContext(NotificationContext);
    const isActiveStatic = room.staticBannerId === banner.id;
    const isActiveScrolling = room.scrollingBannerId === banner.id;
    const [bannerText, setBannerText] = useState(banner.text);
    const textAreaRef = useRef(null);

    // Set banner active / inactive
    const [setBanner] = useMutation(api.UPDATE_ROOM, {
        client: client,
        notifyOnNetworkStatusChange: true,
        update(cache, { data: { updateRoom } }) {
            const updateRoomFragment = gql(`
                fragment UpdateRoom on Room {
                    scrollingBannerId
                    staticBannerId
                }
            `);

            cache.writeFragment({
                id: cache.identify(updateRoom),
                fragment: updateRoomFragment,
                data: updateRoom
            });
        }, onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const toggleScrollingBanner = (event) => {
        event.stopPropagation();

        setBanner({
            variables: {
                scrollingBannerId: isActiveScrolling ? null : banner.id,
                staticBannerId: isActiveScrolling ? room.staticBannerId : (isActiveStatic ? null : room.staticBannerId)
            }
        });
    };

    const toggleStaticBanner = (event) => {
        event.stopPropagation();

        setBanner({
            variables: {
                staticBannerId: isActiveStatic ? null : banner.id,
                scrollingBannerId: isActiveStatic ? room.scrollingBannerId : (isActiveScrolling ? null : room.scrollingBannerId)
            }
        });
    };

    // Editing mode
    const [updateBanner] = useMutation(api.UPDATE_BANNER, {
        client: client,
        notifyOnNetworkStatusChange: true,
        update(cache, { data: { updateBanner } }) {
            const updateBannerFragment = gql(`
            fragment BannerUpdate on Banner {
                id
                text
            }
        `);
            cache.writeFragment({
                id: cache.identify(updateBanner),
                fragment: updateBannerFragment,
                data: updateBanner,
            });
        }, onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const doStartEditing = () => {
        startEditing();
        if (textAreaRef.current != null) textAreaRef.current.focus();
    }

    const storeUpdateBanner = () => {
        if (bannerText === null || bannerText === "") {
            setBannerText(banner.text);
            stopEditing();
            return;
        }

        updateBanner({ variables: { id: banner.id, text: bannerText } });
        stopEditing();
    }

    // Delete
    const [doDelete] = useMutation(api.DELETE_BANNER, {
        client: client,
        notifyOnNetworkStatusChange: true,
        update(cache, { data: { deleteBanner } }) {
            cache.modify({
                fields: {
                    banners(refs, { readField }) {
                        return refs.filter(
                            bannerRef => deleteBanner.id !== readField('id', bannerRef)
                        );
                    },
                },
            });
        }, onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const deleteBanner = () => {
        stopEditing();

        if (!window.confirm("Are you sure that you want to delete this banner?")) return;

        doDelete({ variables: { id: banner.id } });
    }

    const classNames = ["banner-item"];
    if (isEditing) classNames.push("banner-item--editing");
    if (!isEditing && (isActiveScrolling || isActiveStatic)) classNames.push("banner-item--active");

    const editingButtons = [
        <button key="button-delete" onClick={deleteBanner} title="Delete the banner"><DeleteImg alt="Delete" /></button>,
        <button key="button-save" onClick={storeUpdateBanner} title="Save changes"><OkImg alt="Save" /></button>
    ];

    const staticButtons = [
        <button key="button-static" className={isActiveStatic ? "active" : null} title="Show banner" onClick={toggleStaticBanner}><StaticImg alt="Static" /></button>,
        <button key="button-scrolling" className={isActiveScrolling ? "active" : null} title="Show banner as ticker" onClick={toggleScrollingBanner}><TickerImg alt="Ticker" /></button>
    ];

    return (
        <div onClick={isEditing ? null : doStartEditing} className={classNames.join(" ")}>
            <div className="banner-item-text">
                <TextareaAutosize
                    placeholder="Enter text"
                    value={bannerText}
                    ref={textAreaRef}
                    readOnly={!isEditing}
                    maxLength={200}
                    maxRows={15}
                    onChange={(e) => setBannerText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            storeUpdateBanner();
                        } else if (e.keyCode === 27) {
                            setBannerText(banner.text);
                            stopEditing();
                        }
                    }} />
            </div>

            <div className="banner-item-actions">{isEditing ? editingButtons : staticButtons}</div>
        </div>
    );
}

function AddBannerForm({ onCreated, onCancel, client }) {
    const notificationContext = useContext(NotificationContext)

    const [addBanner, { loading }] = useMutation(api.ADD_BANNER, {
        client: client,
        notifyOnNetworkStatusChange: true,
        update(cache, { data: { createBanner } }) {
            cache.modify({
                fields: {
                    banners(existingBanners = []) {
                        const newBannerRef = cache.writeFragment({
                            data: createBanner,
                            fragment: gql(`
                            fragment NewBanner on Banner {
                                id
                                roomId
                                text
                            }
                        `)
                        });

                        return [...existingBanners, newBannerRef];
                    }
                }
            });
        }, onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const [text, setText] = useState("");

    const reset = (event) => {
        event.preventDefault();
        setText("");
        onCancel();
    };

    const submit = (event) => {
        event.preventDefault();
        addBanner({ variables: { text } }).then(onCreated);
        setText("");
    };

    return <>
        <div className="sidebar-content-header">
            <span>Banners</span>
        </div>
        <div className="sidebar-content-body">
            <Center>
                <form onSubmit={submit}>
                    <h3>Create a new banner</h3>
                    <p className="text-muted">What's the content of your banner?</p>
                    <TextareaAutosize
                        placeholder="Enter text"
                        autoFocus
                        className="form-control mb-2 text-center"
                        value={text}
                        onKeyDown={(event) => {
                            if (event.keyCode === 13 && !event.shiftKey) {
                                submit(event);
                            }
                        }}
                        maxLength={200}
                        style={{ resize: "none" }}
                        onChange={(e) => {
                            setText(e.currentTarget.value)
                        }} />
                    <button type="submit" className="btn btn-primary w-100 mb-2" disabled={!text.length || loading}>Create</button>
                    <button onClick={reset} className="btn btn-light w-100" disabled={loading}>Cancel</button>
                </form>
            </Center>
        </div>
    </>;
}
