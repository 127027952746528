import * as React from 'react';
import { RtcContext } from '../../contexts/RtcContext';
import { NotificationContext } from '../../contexts/NotificationContext'
import Banner from './sidebar/Banner';
import Theme from './sidebar/Theme';
import Chat from './sidebar/Chat';
import Settings from './sidebar/Settings';
import StreamIns from './sidebar/StreamIns';
import { ReactComponent as MessagesImg } from '../../assets/img/icons/messages.svg';
import { ReactComponent as ThemeImg } from '../../assets/img/icons/brand.svg';
import { ReactComponent as BannerImg } from '../../assets/img/icons/banner.svg';
import { ReactComponent as SettingsImage } from '../../assets/img/icons/settings.svg';
import { ReactComponent as LivestreamImage } from '../../assets/img/icons/livestream.svg';
import { ReactComponent as StreamingInsImage } from '../../assets/img/icons/film-outline.svg';
import { Livestream } from './sidebar/Livestream';
import * as api from '../../api';
import { useQuery } from '@apollo/client';

const DEFAULT_ITEM = "chat";
const CACHED_ITEMS = ["livestream", "theme", "banner", "chat"];

export const Sidebar = React.forwardRef((props, ref) => {
    React.useImperativeHandle(ref, () => ({
        setLivestreamActive(livestreamId) {
            if (activeItem !== "livestream") _onClick("livestream")
            livestreamRef.current?.setSelectedStream(livestreamId)
        }
    }));

    const { loading, error, data, refetch } = useQuery(api.GET_LIVESTREAMS, {
        client: props.adminClient,
        skip: props.adminClient == null,
        variables: { allowedStates: ['RUNNING', 'PENDING'] },
        pollInterval: 30000, // TODO: We will change to a subscription later.
        onError(error) {
            console.error(error)
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    })

    const rtcContext = React.useContext(RtcContext)
    const notificationContext = React.useContext(NotificationContext)
    const [opened, setOpened] = React.useState(window.innerWidth > 1024)
    const [activeItem, setActiveItem] = React.useState(DEFAULT_ITEM)
    const [renderedItems, setRenderedItems] = React.useState([DEFAULT_ITEM])
    const [indicators, setIndicators] = React.useState({})
    const livestreamRef = React.useRef()


    const _renderIndicator = (which) => {
        if (!indicators[which]) return;
        return <i>{Math.min(indicators[which], 99)}</i>;
    }

    const _renderItems = () => {
        const userToken = rtcContext.user.isAdmin ? rtcContext.token : rtcContext.user.id
        const filteredLivestreams = data?.livestreams.filter((stream) => stream.inputUrl?.startsWith(process.env.REACT_APP_HOST) && stream.state !== "DONE" && stream.chatEnabled)
        const chatRooms = [{ name: 'Studio Chat', id: rtcContext.roomName, token: userToken }]
        filteredLivestreams?.forEach(stream => {
            chatRooms.push({ name: stream.name, id: `livestream:${stream.id}`, token: stream.chatToken })
        });
        const items = {
            "livestream": <Livestream ref={livestreamRef} client={props.adminClient} livestreams={data?.livestreams} loading={loading} error={error} refetch={refetch} showLivestreamModal={props.showLivestreamModal} showShareVideoModal={props.showShareVideoModal} />,
            "theme": <Theme client={props.client} roomId={rtcContext.roomName} />,
            "banner": <Banner client={props.client} room={props.room} />,
            "chat": <Chat onIncomingMessage={() => _incrementIndicator("chat")} isVisible={activeItem === "chat" && opened} rooms={chatRooms} />,
            "settings": <Settings />,
            "stream-ins": <StreamIns startStreamModalRef={props.startStreamModalRef} />
        };

        return Object.entries(items).map(([name, component]) => {
            const isVisible = activeItem === name;

            return <div key={name} className={`sidebar-content-item ${isVisible ? "sidebar-content-item--visible" : ""}`}>
                {(activeItem === name || renderedItems.indexOf(name) > -1) && component}
            </div>
        });
    }

    const _isActive = (which) => {
        return activeItem === which ? "active" : null;
    }

    const _onClick = (which) => {
        if (opened && activeItem === which) {
            setOpened(false)
            return;
        }

        setOpened(true)
        setActiveItem(which)
        setIndicators({ ...indicators, [activeItem]: 0, [which]: 0 })

        if (CACHED_ITEMS.indexOf(which) > -1 && !renderedItems.includes(which)) {
            setRenderedItems((curItems) => {
                const tmp = new Set(curItems);
                tmp.add(which);
                return Array.from(tmp);
            });
        }
    }

    const _incrementIndicator = (which) => {
        if (activeItem === which && opened) return false;
        setIndicators({
            ...indicators,
            [which]: (indicators[which] || 0) + 1
        })
    }


    let className = "sidebar border-start flex-shrink-0";
    if (opened) className += " opened";

    return (
        <div className={className}>
            <div className="sidebar-menu border-start">
                <button
                    title="Chat" className={_isActive("chat")}
                    onClick={_onClick.bind(this, "chat")}>
                    <MessagesImg /><br />
                    <span>Chat</span>
                    {_renderIndicator("chat")}
                </button>

                {rtcContext.user.isAdmin && <button
                    title="Theme" className={_isActive("theme")}
                    onClick={_onClick.bind(this, "theme")}>
                    <ThemeImg /><br />
                    <span>Theme</span>
                </button>}

                {rtcContext.user.isAdmin && <button
                    title="Banner" className={_isActive("banner")}
                    onClick={_onClick.bind(this, "banner")}>
                    <BannerImg /><br />
                    <span>Banner</span>
                </button>}

                {rtcContext.serviceToken != null && <button title="Livestream" className={_isActive("livestream")}
                    onClick={_onClick.bind(this, "livestream")}>
                    <LivestreamImage /><br />
                    <span>Livestream</span>
                </button>}

                {rtcContext.user.isAdmin && <button
                    title="Video-ins" className={_isActive("stream-ins")}
                    onClick={_onClick.bind(this, "stream-ins")}>
                    <StreamingInsImage /><br />
                    <span>Video-ins</span>
                </button>}

                <button
                    title="Settings" className={_isActive("settings")}
                    onClick={_onClick.bind(this, "settings")}>
                    <SettingsImage /><br />
                    <span>Settings</span>
                </button>
            </div>
            <div className="sidebar-content border-start">
                {_renderItems()}
            </div>
        </div>
    );

});


export function Center({ children }) {
    return (
        <div className="h-100 text-center p-3 d-flex flex-column align-items-center justify-content-center">
            {children}
        </div>
    );
}
