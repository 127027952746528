import { useMutation } from "@apollo/client";
import { forwardRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import { START_STREAM_IN, STOP_STREAM_IN } from "../../../api";
import { NotificationContext } from "../../../contexts/NotificationContext";
import { RtcContext } from "../../../contexts/RtcContext";
import { InputWithValidation, isStreamIn } from "../../../helper/Helper";
import { BaseModal } from "../../general/BaseModal";
import { Center } from '../Sidebar';
import { getErrors, hasError, globalErrors } from '../../../helper/ErrorHelpers';

export default function StreamIns({ startStreamModalRef }) {
    const rtcContext = useContext(RtcContext);
    const streams = [...rtcContext.streams].sort((s) => s.id).filter(isStreamIn);

    const renderStream = (stream, index) => {
        return <StreamItem key={stream.id} remoteStream={stream} name={`Video stream #${index + 1}`} />;
    }

    if (streams.length === 0) {
        return <>
            <div className="sidebar-content-header">
                <span>Video inputs</span>
            </div>
            <div className="sidebar-content-body">
                <Center>
                    <h3>There are no running video input streams.</h3>
                    <p className="text-muted">Add a RTMP or RTSP/RTP stream you want to include as stream within your Video.Taxi Studio canvas.</p>
                    <button className="btn btn-primary" onClick={startStreamModalRef.current?.show}>Add video stream</button>
                </Center>
            </div>
        </>;
    }

    return <>
        <div className="sidebar-content-header">
            <span>Stream Inputs</span>
            <button className="btn btn-sm btn-primary ms-auto" onClick={startStreamModalRef.current?.show}>Add video stream</button>
        </div>
        <div className="sidebar-content-body">
            {streams.map(renderStream)}
        </div>
    </>;
}

function StreamItem({ remoteStream, name }) {
    const videoRef = useRef(null);
    const notificationContext = useContext(NotificationContext);

    const [stopStream, { loading }] = useMutation(STOP_STREAM_IN, {
        variables: { id: remoteStream.id },
        onError(error) {
            notificationContext.error("Error", error.message)
        }
    });

    useEffect(() => {
        if (videoRef.current == null) return;
        videoRef.current.srcObject = remoteStream.mediaStream;
    }, [remoteStream]);


    const onRemove = () => {
        window.confirm("Are you sure that you want to remove the video stream?") && stopStream();
    }

    return (
        <div className="row align-items-center border-bottom p-3 g-2">
            <div className="col-5">
                <div className="ratio ratio-16x9 rounded-3 overflow-hidden">
                    <video ref={videoRef} autoPlay playsInline muted style={{ objectFit: "cover", objectPosition: "center" }} className="bg-dark" />
                </div>
            </div>
            <div className="col-7">
                <div className="text-truncate">{name}</div>
                <button className="btn btn-sm btn-danger" disabled={loading} onClick={onRemove}>Remove stream</button>
            </div>
        </div >
    );
}

export const StartStreamModal = forwardRef((props, ref) => {
    const notificationContext = useContext(NotificationContext);
    const [inputUrl, setInputUrl] = useState("");
    const [errors, setErrors] = useState([]);

    const [startStreamIn, { loading }] = useMutation(START_STREAM_IN, {
        variables: {
            url: inputUrl,
            transportProtocol: "udp",
            bufferSize: 8192
        },
        onCompleted() {
            notificationContext.success("Your video input was added successfully", "You have successfully added a video input. Add it to the conference to show it to everyone.");
            ref.current?.hide();
            reset();
        },
        onError(error) {
            console.error(error);
            error.graphQLErrors.forEach((e) => e.path.shift());
            setErrors(error.graphQLErrors);
        }
    });

    const reset = useCallback(() => {
        setInputUrl("");
        setErrors([]);
    }, [setInputUrl, setErrors]);

    const onSubmit = (event) => {
        event.preventDefault();
        startStreamIn({ attributes: { url: inputUrl } });
    }

    return (
        <BaseModal size="md" dismissable ref={ref} onHide={reset}>
            <div className="modal-header">
                <h5 className="modal-title">Add video input</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                {globalErrors(errors, ["url"]) || <p className="alert alert-info">Add your RTMP or RTSP/RTP stream URL you want to include as stream within your Video.Taxi Studio canvas. This URL will get pulled from your Studio</p>}

                <form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <label htmlFor="inputUrl" className="form-label">Input URL</label>
                        <InputWithValidation type="text" name="name" value={inputUrl} onChange={(e) => setInputUrl(e.target.value)} className="form-control" disabled={loading} isInvalid={hasError(errors, "url")} forceErrors={true} id="inputUrl" placeholder="From where do you want to pull your video input?" autoComplete="new-password" />
                        {hasError(errors, "url") && <div className="invalid-feedback d-block">{getErrors(errors, "url")}</div>}
                    </div>

                    <button disabled={loading} onClick={startStreamIn} className="btn btn-primary">Add video stream</button>
                </form>
            </div>
        </BaseModal>
    );
});
