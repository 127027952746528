import React, { forwardRef, useEffect, useState, useRef, useContext } from 'react';
import Header from './components/general/Header';
import * as api from './api';
import Studio from './components/Studio';
import RtcContextProvider from './contexts/RtcContext';
import { SettingsContext } from './contexts/SettingsContext';
import RoomProvider from './components/general/RoomProvider';
import NotificationContextProvider, { NotificationContext } from './contexts/NotificationContext';
import MediaDevicesCheck from './components/general/MediaDevicesCheck';
import Canvas from "./components/studio/Canvas";
import { createApolloClient } from "./helper/Helper";
import { PaymentPanel } from './components/general/PaymentPanel';
import { Modal } from 'bootstrap';
import LivestreamForm from './components/payment/forms/LivestreamForm';
import ExtensionForm from './components/payment/forms/ExtensionForm';

export default class Component extends React.Component {
	constructor(props) {
		super(props)
		this._studioRef = React.createRef();
		this._modalRef = React.createRef();
		this.state = {
			userName: sessionStorage.getItem("userName"),
			stream: null,
		}
		this.joinConference = this.joinConference.bind(this)
	}

	_openLivestream(livestreamId) {
		if (this._studioRef.current && livestreamId) this._studioRef.current.onSuccessPayment(livestreamId)
	}

	render() {
		if (this.props.justShowCanvas) {
			return (
				<div style={{ width: "100vw", height: "100vh", backgroundColor: "black", cursor: "none", overflow: "hidden" }}>
					<RtcContextProvider
						isRecorder={true}
						client={this.props.client}
						userName="Recorder"
						roomName={this.props.roomName}>
						<RoomProvider roomId={this.props.roomName}>
							{(room) => <Canvas room={room} showCanvas={true} />}
						</RoomProvider>
					</RtcContextProvider>
				</div>
			);
		} else if (this.state.userName) {
			return (
				<SettingsContext.Consumer>
					{(context) => {
						return <NotificationContextProvider>
							<RtcContextProvider
								isRecorder={false}
								mediaStream={this.state.stream}
								settingsContext={context}
								userName={this.state.userName}
								token={this.props.token}
								client={this.props.client}
								roomName={this.props.roomName}
								videoId={this.state.videoId}
								serviceToken={this.serviceToken}
								serviceUrl={this.serviceUrl}
								audioId={this.state.audioId}>
								<div className="d-flex flex-column w-100" style={{ height: "100vh" }}>
									{this._createIfNedeedClient()}
								</div>
							</RtcContextProvider>
						</NotificationContextProvider>
					}}
				</SettingsContext.Consumer>
			);
		} else {
			return (
				<div className="flex-fill h-100" style={{ overflowY: "auto" }}>
					<MediaDevicesCheck joinConference={this.joinConference} />
				</div>
			);
		}
	}

	get serviceToken() {
		if (this.props.token == null) return null;

		const parts = (this.props.token || "").split(".");
		if (parts.length !== 3) return null;

		const tokenInfo = JSON.parse(atob(parts[1]));
		return tokenInfo.token;
	}

	get serviceUrl() {
		if (this.props.token == null) return null;

		const parts = (this.props.token || "").split(".");
		if (parts.length !== 3) return null;

		const tokenInfo = JSON.parse(atob(parts[1]));
		return tokenInfo.iss;
	}


	_showModal(stream) {
		this._modalRef.current.showModal(stream);
	}

	_createIfNedeedClient() {
		if (this.serviceToken != null) {
			const httpOptions = { uri: `${this.serviceUrl}/graphql` };
			const client = createApolloClient(httpOptions, this.serviceToken);
			return <>
				<ModalPayment ref={this._modalRef} client={client} onSuccess={(livestreamId) => this._openLivestream(livestreamId)} />
				<Header showLivestreamModal={() => this._showModal()} />
				<RoomProvider client={this.props.client} roomId={this.props.roomName}>
					{(room) => <Studio ref={this._studioRef} client={this.props.client} adminClient={client} room={room} showLivestreamModal={(stream) => this._showModal(stream)} />}
				</RoomProvider>
			</>
		} else {
			return <>
				<Header />
				<RoomProvider roomId={this.props.roomName}>
					{(room) => <Studio room={room} client={this.props.client} />}
				</RoomProvider>
			</>
		}

	}


	joinConference(userName, stream) {
		sessionStorage.setItem('userName', userName);
		this.setState({ userName, stream });
	}
}

const ModalPayment = forwardRef(({ client, onSuccess }, ref) => {
	const modalRef = useRef();
	const modalInstanceRef = useRef();
	const notificationContext = useContext(NotificationContext);
	const [data, setData] = useState({ stream: null, visible: false });

	React.useImperativeHandle(ref, () => ({
		showModal(stream) {
			setData({ stream, visible: true });
			modalInstanceRef.current.show();
		}
	}));

	const onHide = () => {
		setData({ stream: null, visible: false });
	}

	useEffect(() => {
		const m = new Modal(modalRef.current, { backdrop: 'static', keyboard: false });
		modalRef.current.addEventListener('hidden.bs.modal', onHide)

		modalInstanceRef.current = m;

		const modalRefVal = modalRef.current;

		return () => {
			modalRefVal.removeEventListener('hidden.bs.modal', onHide)
		};
	}, []);

	const renderContent = () => {
		if (data.stream != null) {
			return <PaymentPanel
				key={`extend-${data.stream.id}`}
				client={client}
				estimateQuery={api.ESTIMATE_LIVESTREAM_EXTENSION}
				createMutation={api.EXTEND_LIVESTREAM}
				updateCacheFn={api.EXTEND_LIVESTREAM_UPDATE_CACHE}
				hideModal={() => modalInstanceRef.current.hide()}
				formComponent={ExtensionForm}
				prepareVars={(vars, nonce) => {
					return {
						...vars,
						duration: vars.duration + data.stream.duration,
						paymentNonce: nonce,
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
					};
				}}
				initialData={{ id: data.stream.id, duration: 3600 }}
				onSuccess={() => {
					const message = { variant: "success", headingText: "Livestream extended", bodyText: "You have successfully extended your livestream.", withTimer: true, timer: 5000 }
					notificationContext.showMessage(message)
				}} />;
		} else {
			return <PaymentPanel
				client={client}
				estimateQuery={api.ESTIMATE_LIVESTREAM_CREATE}
				createMutation={api.CREATE_LIVESTREAM}
				updateCacheFn={api.CREATE_LIVESTREAM_UPDATE_CACHE}
				hideModal={() => modalInstanceRef.current.hide()}
				formComponent={LivestreamForm}
				prepareVars={(attributes, nonce) => {
					return {
						attributes,
						paymentNonce: nonce,
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
					};
				}}
				onSuccess={(result) => {
					onSuccess(result.data.createLivestream.id);

					const message = { variant: "success", headingText: "Thank you! Your payment has been successful.", bodyText: "You have successfully created your livestream.", withTimer: true, timer: 5000 }
					notificationContext.showMessage(message)
				}} />;
		}
	}

	return (
		<div className="modal fade" ref={modalRef} tabIndex="-1" aria-hidden="true" >
			<div className="modal-dialog modal-lg">
				<div className="modal-content">{data.visible && renderContent()}</div>
			</div>
		</div>
	);
});
