import { Modal } from 'bootstrap';
import * as React from 'react';
import { NotificationContext } from '../contexts/NotificationContext';
import { chatEmbedUrl } from '../helper/Helper';

export class ShareVideoModal extends React.Component {
    static contextType = NotificationContext;
    constructor(props) {
        super(props);
        this._modalRef = React.createRef(null)
        this.state = {
            streamId: null,
            embedSnippet: null,
            embedUrl: null,
            chatEnabled: null,
            chatOverlay: true
        }
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        this.modal = new Modal(this._modalRef.current)
        this._modalRef.current.addEventListener('hidden.bs.modal', this.onHide)
    }

    componentWillUnmount() {
        this._modalRef.current.removeEventListener('hidden.bs.modal', this.onHide)
        this.modal.dispose();
    }

    showModal(stream) {
        this.setState({
            streamId: stream.id,
            embedSnippet: stream.embedSnippet,
            embedUrl: stream.embedUrl,
            chatEnabled: stream.chatEnabled
        }, () => this.modal.show());
    }

    _copyCodeToClipboard(text) {
        navigator.clipboard.writeText(text)
        const message = { variant: "success", headingText: "Copied", bodyText: "Text copied to clipboard", withTimer: true, timer: 5000 }
        this.context.showMessage(message)
    }

    onHide() {
        this.setState({
            streamId: null,
            embedSnippet: null,
            embedUrl: null,
            chatEnabled: null
        })
    }

    _chatSnippet() {
        const url = chatEmbedUrl(`livestream:${this.state.streamId}`);
        return `<iframe width="350" height="478" src="${url}" frameborder="0"></iframe>`;
    }

    _playerSnippet() {
        if (this.state.chatOverlay) return this.state.embedSnippet;

        const wrapper = document.createElement("div");
        wrapper.innerHTML = this.state.embedSnippet;

        const iframe = wrapper.firstChild;

        const url = new URL(iframe.src);
        url.searchParams.set("chat", this.state.chatOverlay);
        iframe.src = url.toString();

        return wrapper.innerHTML;
    }

    _embedUrl() {
        if (this.state.chatOverlay) return this.state.embedUrl;

        const url = new URL(this.state.embedUrl);
        url.searchParams.set("chat", this.state.chatOverlay);
        return url.toString();
    }

    _renderBody() {
        return (
            <div className="modal-body p-0">
                {this._renderPlayer()}

                <div className="p-3 border-bottom">
                    Copy and paste the following HTML code snippet into your website at the position you want to include your player.
                    This player is for your public audience. That's it, you are ready to start.
                </div>

                <div className="row g-0 border-bottom">
                    <div className="col-lg-3 col-12 p-3">
                        <h5 className="fw-bold">E-Mail, Social</h5>
                        <small className="text-muted">To share in social networks</small>
                    </div>

                    <div className=" col-lg-7 col-12 p-3 text-break d-flex align-items-center bg-light">
                        {this.state.embedUrl}
                    </div>
                    <div className="col-lg-2 col-12 d-flex align-items-center bg-light justify-content-center">
                        <button onClick={() => this._copyCodeToClipboard(this.state.embedUrl)} className="btn my-2" style={{ color: "#167ac6" }}>Copy</button>
                    </div>
                </div>

                {this.state.chatEnabled && <div className="row g-0 border-bottom">
                    <div className="col-lg-3 col-12 p-3">
                    </div>

                    <div className=" col-lg-7 col-12 p-3 text-break bg-light">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={this.state.chatOverlay} onChange={(e) => this.setState({ chatOverlay: e.currentTarget.checked })} id="embed_include_chat" />
                            <label className="form-check-label" htmlFor="embed_include_chat">Include chat overlay in player</label>
                        </div>
                    </div>
                    <div className="col-lg-2 col-12 bg-light">
                    </div>
                </div>}

                <div className="row g-0 border-bottom">
                    <div className="col-lg-3 col-12 p-3">
                        <h5 className="fw-bold">HTML Snippet</h5>
                        <small className="text-muted">To include on your website</small>
                    </div>
                    <div className="col-lg-7 col-12 p-3 text-break d-flex align-items-center bg-light">{this._playerSnippet()}</div>
                    <div className="col-lg-2 col-12 d-flex align-items-center bg-light justify-content-center">
                        <button onClick={() => this._copyCodeToClipboard(this._playerSnippet())} className="btn my-2" style={{ color: "#167ac6" }}>Copy</button>
                    </div>
                </div>

                {this.state.chatEnabled && !this.state.chatOverlay && <div className="row g-0">
                    <div className="col-lg-3 col-12 p-3">
                        <h5 className="fw-bold">Chat Snippet</h5>
                        <small className="text-muted">for a separate chat on your website excluded from the player</small>
                    </div>

                    <div className=" col-lg-7 col-12 p-3 text-break d-flex align-items-center bg-light">{this._chatSnippet()}</div>
                    <div className="col-lg-2 col-12 d-flex align-items-center bg-light justify-content-center">
                        <button onClick={() => this._copyCodeToClipboard(this._chatSnippet())} className="btn my-2" style={{ color: "#167ac6" }}>Copy</button>
                    </div>
                </div>}

                <div className="modal-footer">
                    <button onClick={() => this.modal.hide()} className="btn btn-primary">Close</button>
                </div>
            </div>
        );
    }

    _renderPlayer() {
        return <div style={{ backgroundColor: "black", width: "100%", height: "0px", paddingBottom: "56.25%", position: "relative" }}>
            <iframe className="position-absolute w-100 h-100" src={this._embedUrl()} title="VideoTaxi video player" frameBorder="0" allow="accelerometer; autoplay; gyroscope;" allowFullScreen></iframe>
        </div>
    }

    render() {
        return <div className="modal fade" ref={this._modalRef} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    {this.state.embedSnippet && this.state.embedUrl && this._renderBody()}
                </div>
            </div>
        </div>
    }
}
