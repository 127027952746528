import { useContext, useRef, forwardRef } from 'react';
import { RtcContext } from '../../contexts/RtcContext';
import { NotificationContext } from "../../contexts/NotificationContext";
import { BaseModal } from "../general/BaseModal";
import { ReactComponent as MicMutedImg } from '../../assets/img/icons/mic-mute.svg';
import { ReactComponent as VideoMutedImg } from '../../assets/img/icons/video-mute.svg';
import { ReactComponent as ScreenImg } from '../../assets/img/icons/screen.svg';
import { ReactComponent as AddPersonImg } from '../../assets/img/icons/add-person.svg';
import { ReactComponent as CloseImg } from '../../assets/img/icons/close.svg';

export default function StreamControl({ isFullscreen, opacity, inviteModalRef }) {
    const context = useContext(RtcContext);
    const audioMuted = context.isAudioMuted();
    const videoMuted = context.isVideoMuted();

    return <>
        {!isFullscreen && <div style={{ height: "60px" }}></div>}

        <div className="stream-control border-top d-flex justify-content-center" style={{ opacity: opacity }}>
            <StreamControlButton
                name="Mute Audio"
                icon={<MicMutedImg />}
                active={audioMuted}
                action={() => context.muteAudio(!audioMuted)} />

            <StreamControlButton
                name="Stop Video"
                icon={<VideoMutedImg />}
                active={videoMuted}
                action={() => context.muteVideo(!videoMuted)} />

            {!isFullscreen && <StreamControlButton
                name="Share Screen"
                icon={<ScreenImg />}
                action={() => context.shareScreen()} />}

            {context.user.isAdmin && !isFullscreen && inviteModalRef?.current != null && <StreamControlButton
                name="Invite"
                hideOnParticipant={true}
                icon={<AddPersonImg />}
                action={inviteModalRef.current.show} />}

            <StreamControlButton
                name="Leave"
                icon={<CloseImg />}
                action={() => {
                    sessionStorage.removeItem('userName');
                    sessionStorage.removeItem('videoId');
                    sessionStorage.removeItem('audioId');
                    window.location.href = '';
                }} />
        </div>
    </>;
}

function StreamControlButton({ disabled, active, name, action, icon }) {
    return (
        <button disabled={disabled} className={active ? "active" : null} title={name} onClick={action}>
            {icon}<br />
            <span>{name}</span>
        </button>
    );
}

export const InviteModal = forwardRef((props, ref) => {
    const notificationContext = useContext(NotificationContext);
    const inputRef = useRef(null);

    const onCopy = () => {
        inputRef.current.select();
        document.execCommand("copy");

        notificationContext.success("Share link copied to clipboard", "You can now share the URL with your guests.");
        ref.current?.hide();
    }

    return (
        <BaseModal size="md" dismissable ref={ref}>
            <div className="modal-header">
                <h5 className="modal-title">Invite People</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <label htmlFor="shareUrl" className="form-label">Share with this link</label>
                <input ref={inputRef} id="shareUrl" className="form-control" type="text" readOnly value={window.location.href} />
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" onClick={onCopy} class="btn btn-primary">Copy</button>
            </div>
        </BaseModal>
    );
});
