import { useState, useRef, useEffect } from 'react';
import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { createUploadMiddleware } from "apollo-absinthe-upload-link";

export function isScreenShare(stream) {
  return stream.source?.video === "screen-cast";
}

export function isStreamIn(stream) {
  return stream.origin === "admin";
}

export function isRecorder(participant) {
  return participant.role === "viewer";
}

export function streamOfParticipant(streams, participantId) {
  return streams.find((s) => s.origin === participantId && !isScreenShare(s));
}

export function getBestResolution(resolutions, desired) {
  if (resolutions.length === 0) {
    return null;
  } else {
    const sorted = [...resolutions].sort((a, b) => a.height - b.height);
    return sorted.find((r) => r.height >= desired) || sorted[sorted.length - 1];
  }
}

export const chatEmbedUrl = (room, params = {}) => {
  const url = new URL(`${process.env.REACT_APP_CHAT_HOST}/${room}`);
  for (const [k, v] of Object.entries(params)) {
    url.searchParams.append(k, v)
  }
  return url.toString();
}

export const withTimeout = function (promise, ms) {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject('Timed out in ' + ms + 'ms.')
    }, ms)
  });

  // Returns a race between our timeout and the passed in promise
  return Promise.race([
    promise,
    timeout
  ]);
}

export function sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export async function createRTCMediaStream(videoId, audioId, useHd) {
  console.log("[createRTCMediaStream]", videoId, audioId, useHd);
  const video = {
    width: { min: 320, ideal: useHd ? 1280 : 800, max: 1280 },
    height: { min: 240, ideal: useHd ? 720 : 600, max: 720 },
    deviceId: videoId,
    frameRate: { ideal: 30, max: 30 }
  }

  const stream = await navigator.mediaDevices.getUserMedia({
    video: video,
    audio: { deviceId: audioId }
  });

  return stream;
}

export function createApolloClient(httpOptions, adminToken, inMemoryCache) {
  const authMiddleware = new ApolloLink((operation, forward) => {
    if (adminToken) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${adminToken}`,
        }
      }));
    }

    return forward(operation);
  });

  return new ApolloClient({
    cache: inMemoryCache ? inMemoryCache : new InMemoryCache(),
    link: from([
      authMiddleware,
      createUploadMiddleware(httpOptions),
      new HttpLink(httpOptions)
    ]),
  });

}

export const InputWithValidation = ({ isInvalid, forceErrors, className, ...props }) => {
  const [wasChanged, setWasChanged] = useState(false);
  const classList = className.split(" ");

  if (isInvalid && (wasChanged || forceErrors)) {
    classList.push("is-invalid");
  }

  return <input {...props} className={classList.join(" ")} onBlur={() => setWasChanged(true)} />;
}

export function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);
      // Add event listener
      element.addEventListener(eventName, eventListener);
      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}
