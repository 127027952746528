import { useState, Component, createRef, useRef, useEffect } from 'react';
import { RtcContext } from '../../contexts/RtcContext';
import { wrapGrid } from 'animate-css-grid';
import StaticBanner from './canvas/StaticBanner';
import ScrollingBanner from './canvas/ScrollingBanner';
import { ReactComponent as Fullscreen } from "../../assets/img/icons/fullscreen.svg";
import StreamControl from '../studio/StreamControl';
import { streamOfParticipant } from '../../helper/Helper';

export default class Canvas extends Component {
    static contextType = RtcContext;

    constructor(props) {
        super(props);

        this.state = {
            isFullscreen: false,
            showStreamControl: false
        }

        this.timeout = null;
        this.gridRef = createRef();
        this.canvasRef = createRef();

        this._onFullscreenChanged = this._onFullscreenChanged.bind(this)
    }


    componentDidMount() {
        this.fullscreenEnabled = (document.fullscreenEnabled || document.webkitFullscreenEnabled) && !this.props.showCanvas ? true : false
        wrapGrid(this.gridRef.current, { easing: 'easeInOut', stagger: 0, duration: 150 });
        this._addListener()
    }

    componentWillUnmount() {
        this._removeListener();
    }

    render() {
        const participants = this.context.visibleParticipants
            .map((pId) => this.context.users.find((u) => u.id === pId))
            .filter((p) => p != null);
        const participantIds = participants.map((p) => p.id);

        const visibleStream = this.context.streams.find((s) => s.id === this.context.visibleStream);

        // check which style actually to use
        let style = this.context.layout;
        if (this.context.layout.indexOf("screen") > -1 && visibleStream == null) {
            style = 'grid';
        }

        // check limits and minimum
        let maxUsers = 0;
        let supportsStream = false;
        switch (style) {
            case 'solo':
                maxUsers = 1;
                break;
            case 'grid':
                maxUsers = 8;
                break;
            case 'vertical':
                maxUsers = 6;
                break;
            case 'solo-grid':
                maxUsers = 7;
                break;
            case 'solo-screen':
                maxUsers = 1;
                supportsStream = true;
                break;
            case 'users-screen':
                maxUsers = 8;
                supportsStream = true;
                break;
            case 'screen':
                supportsStream = true;
                break;
            default:
        }

        const visibleParticipants = participants.slice(0, maxUsers);
        const hiddenParticipants = participants.slice(maxUsers).filter((participant) => participant.id !== this.context.user.id);

        const { theme, staticBanner, scrollingBanner } = this.props.room;
        const opacity = this.state.showStreamControl ? 1 : 0;
        const hide = this.state.showStreamControl ? "" : "hide";

        return (
            <div className={`canvas-wrapper ${hide}`} ref={this.canvasRef} onMouseMove={() => this._onMouseMove()} style={{ '--color': theme.color }}>
                <div className={'canvas-fullscreen'}>
                    {this._renderResponsiveImg(theme.backgroundUrls, { className: "canvas-background", alt: "" })}

                    {
                        /*
                         * Because of a bug with Chrome 92 (maybe already in Chrome 91) we have to assign each MediaStream
                         * to a player. If we don't assign it to a player, the MediaStream can be heared.
                         *
                         * Steps to reproduce:
                         * 1. Add a RTMP stream to a conference
                         * 2. Open the Studio as a viewer (not admin)
                         * 3. Change preferLocalStream to `false` in RtcContext.
                         *    The reason for this is, that there has to be at least one player that renders a OWT MediaStream in the DOM.
                         *
                         * Expected:
                         * You shouldn't hear anything.
                         *
                         * Actual:
                         * You hear the RTMP stream even though its not rendered anywhere.
                         */
                        this.context.streams
                            .filter((s) => participantIds.indexOf(s.origin) === -1)
                            .map((s) => <HiddenAudio key={s.id} mediaStream={s.mediaStream} />)
                    }

                    <div className={`canvas canvas--${style}`} data-count={visibleParticipants.length} ref={this.gridRef} style={{ backgroundImage: theme.backgroundUrl && `url("${theme.backgroundUrl}")` }}>
                        {visibleParticipants.map((participant) => {
                            const stream = streamOfParticipant(this.context.streams, participant.id);

                            return <CanvasItem
                                key={participant.id}
                                remoteStream={stream}
                                name={!staticBanner && !scrollingBanner && theme.showNames ? participant.userId : null}
                                participantId={participant.id}
                                objectFit="cover"
                                onDrop={(pId) => this.context.swapParticipant(pId, participant.id)}
                                volume={this.context.getVolume(stream?.id)}
                                muted={participant.id === this.context.user.id}
                                draggable={this.context.user.isAdmin && visibleParticipants.length > 1} />;
                        })}

                        {supportsStream && visibleStream != null && <CanvasItem
                            remoteStream={visibleStream}
                            objectFit="contain"
                            volume={this.context.getVolume(visibleStream.id)}
                            muted={visibleStream.origin === this.context.user.id} />}
                    </div>

                    {this._renderResponsiveImg(theme.overlayUrls, { className: "canvas-overlay", alt: "" })}
                    {this._renderResponsiveImg(theme.logoUrls, { className: "canvas-logo", alt: "" })}

                    {hiddenParticipants
                        .map((participant) => streamOfParticipant(this.context.streams, participant.id))
                        .filter((stream) => stream != null)
                        .map((stream) => <CanvasAudio key={stream.id} mediaStream={stream.mediaStream} volume={this.context.getVolume(stream.id)} />)}

                    <StaticBanner background={theme.color}>{staticBanner}</StaticBanner>
                    <ScrollingBanner background={theme.color}>{scrollingBanner}</ScrollingBanner>

                    {this.fullscreenEnabled && <button disabled={this.state.isFullscreen && opacity === 0} className="btn btn-sm ms-1 fullscreen-button text-nowrap align-items-center" style={{ opacity: opacity }} onClick={() => this._toggleFullscreen()}> <Fullscreen width={40} height={40} /> </button>}
                    {this.state.isFullscreen && <StreamControl opacity={opacity} isFullscreen={true} />}

                    {!this.context.isRecorder && this.context.isRecording() && <div className="canvas-recording" />}
                </div>
            </div>
        );
    }

    _renderResponsiveImg(urls, props) {
        if (urls == null || Object.values(urls).some((u) => u == null)) return;

        return <img
            src={urls.hd}
            srcSet={`${urls.hd} 1280w, ${urls.fullhd} 1920w, ${urls.wqhd} 2560w, ${urls.uhd} 3840w`}
            alt={"Video.Taxi Studio"}
            sizes={this.state.isFullscreen ? `(max-width: 1280px) 1280px, (max-width: 1920px) 1920px, (max-width: 2560px) 2560px, 3840px` : `1280px`}
            {...props} />;
    }

    _onFullscreenChanged() {
        const isFullscreen = document.fullscreenElement === this.canvasRef.current || document.webkitFullscreenElement === this.canvasRef.current;
        this.setState({ isFullscreen: isFullscreen })
    }

    _removeListener() {
        if (!this.fullscreenEnabled) return;
        document.removeEventListener("fullscreenchange", this._onFullscreenChanged);

        document.removeEventListener("webkitfullscreenchange", this._onFullscreenChanged);
    }

    _addListener() {
        if (!this.fullscreenEnabled) return;
        document.addEventListener("fullscreenchange", this._onFullscreenChanged);

        document.addEventListener("webkitfullscreenchange", this._onFullscreenChanged);

    }

    _openFullscreen(elem) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            elem.webkitRequestFullscreen();
        }
    }

    _closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        }
    }

    _toggleFullscreen() {
        if (this.state.isFullscreen) {
            this._closeFullscreen()
        } else {
            this._openFullscreen(this.canvasRef.current);
        }
    }

    _onMouseMove() {
        if (!this.fullscreenEnabled) return;
        window.clearTimeout(this.timeout)

        this.setState({ showStreamControl: true })
        this.timeout = window.setTimeout(() => this.setState({ showStreamControl: false }), 3000)
    }
}

const CanvasItem = (props) => {
    const [dragging, setDragging] = useState(false);

    const onDragStart = (event) => {
        event.currentTarget.style.zIndex = 1000;
        setDragging(true);
        event.dataTransfer.setData("participantId", props.participantId);
    }

    const onDrop = (event) => {
        event.preventDefault();
        props.onDrop(event.dataTransfer.getData("participantId"));
    }

    const onDragEnd = (event) => {
        event.currentTarget.style.zIndex = 'auto'
        setDragging(false);
    }

    const onDragOver = (event) => {
        if (!dragging && props.draggable) event.preventDefault()
    }

    const renderLoading = () => {
        return <div className="d-flex align-items-center justify-content-center w-100 h-100">
            <div className="spinner-grow text-dark" role="status" style={{ width: "6%", paddingBottom: "6%", height: 0 }}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    };

    const renderStream = () => {
        return <div className="canvas-item-streams">
            <CanvasVideo
                mediaStream={props.remoteStream.mediaStream}
                objectFit={props.objectFit}
                volume={props.volume}
                muted={props.muted} />
        </div>;
    }

    return <div
        className="canvas-item"
        draggable={props.draggable}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}>

        <div>
            {props.remoteStream?.mediaStream != null ? renderStream() : renderLoading()}
            {props.name && <div className="canvas-item-name">{props.name}</div>}
        </div>
    </div>;
};

const CanvasVideo = ({ mediaStream, objectFit, muted, volume }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (!videoRef.current) return;
        if (videoRef.current.srcObject !== mediaStream) {
            videoRef.current.srcObject = mediaStream;
        }
        videoRef.current.volume = volume;
    }, [mediaStream, volume]);

    return <video
        ref={videoRef}
        controls={false}
        style={{ objectFit: objectFit }}
        muted={muted || volume === 0}
        autoPlay
        playsInline />;
}

const CanvasAudio = ({ mediaStream, muted, volume }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (!videoRef.current) return;
        if (videoRef.current.srcObject !== mediaStream) {
            videoRef.current.srcObject = mediaStream;
        }
        videoRef.current.volume = volume;
    }, [mediaStream, volume]);

    return <audio
        ref={videoRef}
        controls={false}
        muted={muted || volume === 0}
        autoPlay
        playsInline />;
}

const HiddenAudio = ({ mediaStream }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (!videoRef.current) return;
        if (videoRef.current.srcObject !== mediaStream) {
            videoRef.current.srcObject = mediaStream;
        }
    }, [mediaStream]);

    return <audio
        ref={videoRef}
        controls={false}
        muted={true}
        playsInline
        autoPlay />;
}

export function getContrastColor(hexcolor) {
    // Convert to RGB value
    var r = parseInt(hexcolor.substr(1, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    // Check contrast
    return (yiq >= 195) ? 'black' : 'white';
}
