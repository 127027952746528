import { useQuery } from '@apollo/client';
import logoArrowUrl from '../../assets/img/logo-arrow.svg';
import { GET_ROOM } from '../../api';

export default function RoomProvider(props) {
    const { data, loading } = useQuery(GET_ROOM, {
        client: props.client,
        variables: { id: props.roomId },
        pollInterval: 5000 // TODO: We will change to a subscription later.
    });

    const defaultTheme = {
        color: "#FCB700",
        logoUrls: {
            fullhd: logoArrowUrl,
            hd: logoArrowUrl,
            uhd: logoArrowUrl,
            wqhd: logoArrowUrl
        },
        backgroundUrls: null,
        overlayUrls: null,
        showNames: true
    };

    if (loading) {
        return props.children({
            scrollingBanner: null,
            staticBanner: null,
            theme: {
                color: "#FCB700",
                logoUrls: null,
                backgroundUrls: null,
                overlayUrls: null
            }
        });
    } else if (data?.room.id) {
        return props.children({
            scrollingBanner: data.room.scrollingBanner,
            scrollingBannerId: data.room.scrollingBannerId,
            staticBannerId: data.room.staticBannerId,
            staticBanner: data.room.staticBanner,
            theme: data.room.theme ? {
                color: data.room.theme.color,
                logoUrls: data.room.theme.logo?.urls,
                backgroundUrls: data.room.theme.background?.urls,
                overlayUrls: data.room.theme.overlay?.urls,
                showNames: data.room.theme.showNames
            } : defaultTheme
        });
    }

    return props.children({
        scrollingBanner: null,
        staticBanner: null,
        theme: defaultTheme
    });
}
