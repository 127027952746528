import { useState, useEffect, useRef } from 'react';
import { getErrors, hasError, globalErrors } from '../../../helper/ErrorHelpers';

const NOOP = () => { };

export default function ExtensionForm({ errors, onChange, onSubmit, initialData }) {
    const [data, setData] = useState(initialData);
    const isFirst = useRef(true);

    const handleFormChange = (event) => {
        const formData = new FormData(event.currentTarget);

        setData({
            ...data,
            duration: formData.get("hours") * 3600 + formData.get("minutes") * 60
        });
    }

    useEffect(() => {
        onChange(data, isFirst.current);
        isFirst.current = false;
    }, [onChange, data]);

    const hours = Math.floor(data.duration / 3600);
    const minutes = Math.floor((data.duration - hours * 3600) / 60);

    return (
        <form onSubmit={onSubmit} onChange={handleFormChange}>
            <h2>Extend livestream</h2>

            {globalErrors(errors, Object.keys(data))}

            <div className="row mb-3">
                {/* duration */}
                <div className="col-md-7">
                    <label className="form-label">Duration</label>
                    <div className="row g-0 has-validation">
                        <div className="col-6 pe-1">
                            <select name="hours" className={`form-select ${hasError(errors, "duration") ? "is-invalid" : ""}`} value={hours} onChange={NOOP}>
                                {[...Array(13).keys()].map((hour) => <option key={hour} value={hour}>{hour}h</option>)}
                            </select>
                        </div>
                        <div className="col-6 ps-1">
                            <select name="minutes" className={`form-select ${hasError(errors, "duration") ? "is-invalid" : ""}`} value={minutes} onChange={NOOP}>
                                {[...Array(60).keys()].map((min) => <option key={min} value={min}>{min}m</option>)}
                            </select>
                        </div>
                    </div>
                    {hasError(errors, "duration") && <div className="invalid-feedback d-block">{getErrors(errors, "duration")}</div>}
                </div>
            </div>
        </form>
    );
}
