import * as React from 'react';
import { SettingsContext } from '../../contexts/SettingsContext';
import { ReactComponent as MicImg } from '../../assets/img/icons/mic.svg';
import { ReactComponent as CloseImg } from '../../assets/img/icons/close.svg';
import { createRTCMediaStream } from '../../helper/Helper';

class DeviceConfig extends React.Component {
    static contextType = SettingsContext;

    audioContext = null;
    analyser = null;
    microphone = null;


    constructor(props) {
        super(props);
        const isMobileSafari = (/iPhone|iPad|iPod/i.test(navigator.userAgent))
        this.enablePreview = (!props.inSettings || (props.inSettings && !isMobileSafari))

        this.videoRef = React.createRef();
        this.volumeRef = React.createRef();
        this.mediaStream = null;
        this.state = {
            selectedVideoId: null,
            selectedAudioId: null,
            useHd: null,
            videoInputs: null,
            audioInputs: null,
            loaded: false,
            videoError: false,
            deviceError: false,
        }
    }

    componentDidUpdate() {
        if (!this.props.setDisabled) return;
        if (this.state.selectedAudioId !== this.context.selectedAudioId || this.state.selectedVideoId !== this.context.selectedVideoId || this.state.useHd !== this.context.useHd) {
            this.props.setDisabled(false)
        } else {
            this.props.setDisabled(true)
        }
    }

    _sortAndSetMediaDevices(devices) {
        const videoInputs = devices.videoInputs;
        const audioInputs = devices.audioInputs;

        let audioIndex;
        let videoIndex;

        const selectedAudioInput = audioInputs.find((a, index) => {
            if (a.deviceId === this.context.selectedAudioId) {
                audioIndex = index
                return true
            }
            return false
        })

        const selectedVideoInput = videoInputs.find((v, index) => {
            if (v.deviceId === this.context.selectedVideoId) {
                videoIndex = index
                return true
            }
            return false
        })

        if (audioIndex) {
            audioInputs.splice(audioIndex, 1);
            audioInputs.unshift(selectedAudioInput)
        }

        else if (videoIndex) {
            videoInputs.splice(videoIndex, 1);
            videoInputs.unshift(selectedVideoInput)
        }

        this.setState({
            audioInputs: audioInputs,
            videoInputs: videoInputs,
            selectedVideoId: videoInputs[0].deviceId,
            selectedAudioId: audioInputs[0].deviceId,
            useHd: this.context.useHd,
            loaded: true
        }, () => {
            if (this.enablePreview) {
                this.changeSelection()
            } else {
                this._setMediaDevices()
            }

        });
    }

    async componentDidMount() {
        navigator.mediaDevices.ondevicechange = () => {
            this.mediaStream = null
            this.getMediaDevices().then((devices) => {
                if (!devices || devices.videoInputs.length === 0 || devices.audioInputs.length === 0) {
                    if (this.props.disableButton)
                        this.props.disableButton(true)
                    this.setState({
                        deviceError: true
                    })
                } else {
                    this.setState({
                        deviceError: false
                    })
                    this._sortAndSetMediaDevices(devices)
                    if (this.props.disableButton)
                        this.props.disableButton(false)
                }
            }).catch((e) => console.warn("ondevicechange error", e))
        }

        const devices = await this.getMediaDevices()
        if (!devices || devices.videoInputs.length === 0 || devices.audioInputs.length === 0) {
            if (this.props.disableButton)
                this.props.disableButton(true)
            this.setState({
                deviceError: true
            })
        } else {
            this.setState({
                deviceError: false
            })
            this._sortAndSetMediaDevices(devices)
            if (this.props.disableButton)
                this.props.disableButton(false)
        }
    }

    componentWillUnmount() {
        if (this.props.inSettings) this.stopVideo();
        this.removeAudioHandle();
    }

    async getMediaDevices() {

        try {
            return navigator.mediaDevices.enumerateDevices().then((devices) => {
                const filteredAudio = devices.filter(
                    (device) => device.kind === 'audioinput'
                );
                const filteredVideo = devices.filter(
                    (device) => device.kind === 'videoinput'
                );

                return { audioInputs: filteredAudio, videoInputs: filteredVideo }
            });
        } catch (error) {
            console.error("Could not get camera", error);

            alert("Please, allow videocamera and microphone usage. " +
                "Go to Settings -> Privacy and Security -> Site settings -> Recent activities, " +
                "allow camera and microphone usage and reload this page in order to use Video Taxi Studio.");
        }
    }

    stopVideo() {
        if (this.mediaStream) {
            this.mediaStream.getTracks().forEach((t) => t.stop())
        }

        if (this.videoRef.current) {
            this.videoRef.current.srcObject = null;
        }
    }

    async enableVideo(videoId, audioId, useHd) {
        const stream = await createRTCMediaStream(videoId, audioId, useHd)
        this.mediaStream = stream
        this.forceUpdate(() => {
            this.addAudioHandle();
            if (this.videoRef.current) this.videoRef.current.srcObject = stream
        });
    }

    _renderPreviewWebcam() {
        if (this.mediaStream) {
            return (
                <div style={{ width: "100%", height: "0px", paddingBottom: "56.25%", position: "relative" }}>
                    <video
                        style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            height: "100%",
                            width: "100%",
                            objectFit: "cover"
                        }}
                        playsInline
                        muted={true}
                        autoPlay={true}
                        ref={this.videoRef} />
                </div>
            );
        } else
            if (this.state.videoError) {
                return (
                    <div style={{ width: "100%", height: "0px", paddingBottom: "56.25%", position: "relative", backgroundColor: "black" }}>
                        <div style={{
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: 'center',
                            flexDirection: "column",
                            alignItems: 'center'
                        }}
                            className="p-3 text-center text-white">
                            <CloseImg style={{ fontSize: 40, color: "red" }} />
                            <div>Fehler beim laden der Video Quelle</div>
                        </div>
                    </div>
                );
            }

        return (
            <div style={{ width: "100%", height: "0px", paddingBottom: "56.25%", position: "relative", backgroundColor: "black" }}>
                <div style={{ width: "100%", height: "100%", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="spinner-grow" role="status">
                        <span className="visually-hidden">Laden Bitte warten...</span>
                    </div>
                </div>
            </div>
        );
    }

    _renderPreview() {
        return (
            <div className="card mb-4">
                <div className="row g-0 h-100">
                    <div className="col-10 h-100 bg-dark">
                        {this._renderPreviewWebcam()}
                    </div>

                    <div className="col-2 d-flex flex-column py-3 align-items-center">
                        <MicImg className="mb-2" color="#9a9a9a" />

                        <div style={{ width: "24px", flex: 1 }} className="rounded-pill bg-light border d-flex align-items-end overflow-hidden">
                            <div
                                className="w-100 rounded-pill"
                                ref={this.volumeRef}
                                style={{ background: 'linear-gradient(to top, #fc4a1a, #f7b733)' }}></div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    render() {
        const inColumn = this.props.inSettings ? "col-12 mb-4" : "col mb-4"

        if (this.state.deviceError)
            return <div className="alert alert-info">Do you have a Webcam attached? Your browser can't access your devices. Close any other applications that might be using your camera/mic
            (Skype, Zoom, Teams), then refresh the page. If that doesn't work, try restarting your browser or computer.</div>


        return (
            <>
                {this.enablePreview && this._renderPreview()}
                <div className="row">
                    <div className={inColumn}>
                        <label htmlFor="input-webcam">Webcam</label>
                        <select
                            onChange={(e) => {
                                this.setState(
                                    { selectedVideoId: e.target.value },
                                    () => this.changeSelection()
                                );
                            }}
                            className="form-select"
                            id="input-webcam">
                            {this.state.loaded && this.state.videoInputs.map((value) => <option value={value.deviceId} key={value.deviceId}>{value.label}</option>)}
                        </select>
                    </div>

                    <div className={inColumn}>
                        <label htmlFor="input-microphone">Microphone</label>

                        <select
                            onChange={(e) => {
                                this.setState(
                                    { selectedAudioId: e.target.value },
                                    () => this.changeSelection()
                                );
                            }}
                            className="form-select"
                            id="input-microphone">
                            {this.state.loaded && this.state.audioInputs.map((value) => <option key={value.deviceId} value={value.deviceId}>{value.label}</option>)}
                        </select>
                    </div>
                </div >
                {
                    this.state.loaded && this.props.inSettings && <>
                        <div className="form-check form-switch">
                            <input checked={this.state.useHd} onChange={(e) => this._toggleUseHd(e)} className="form-check-input" type="checkbox" id="hdResolution" />
                            <label className="form-check-label fw-normal" htmlFor="hdResolution">HD Webcam resolution</label>
                        </div>
                    </>
                }
            </>
        );
    }

    _toggleUseHd(e) {
        this.setState(
            { useHd: e.target.checked },
            () => this.changeSelection()
        );
    }

    // Adds Listeners to audio for displaying the volume meter
    addAudioHandle() {
        this.removeAudioHandle();

        // TODO: We have to remove the old audio listener before. Mabye extract it to a new component?
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = new AudioContext();
        this.analyser = this.audioContext.createAnalyser();
        this.microphone = this.audioContext.createMediaStreamSource(this.mediaStream);
        this.scriptProcessor = this.audioContext.createScriptProcessor(
            2048,
            1,
            1
        );

        this.analyser.smoothingTimeConstant = 0.8;
        this.analyser.fftSize = 1024;

        this.microphone.connect(this.analyser);
        this.analyser.connect(this.scriptProcessor);
        this.scriptProcessor.connect(this.audioContext.destination);
        this.scriptProcessor.onaudioprocess = () => {
            if (!this.volumeRef.current) return;

            var array = new Uint8Array(this.analyser.frequencyBinCount);
            this.analyser.getByteFrequencyData(array);
            var values = 0;

            var length = array.length;
            for (var i = 0; i < length; i++) {
                values += array[i];
            }

            this.volumeRef.current.style.height = `${Math.round(values / length)}%`;
        };
    }

    removeAudioHandle() {
        if (this.microphone != null && this.analyser != null) {
            this.microphone.disconnect(this.analyser);
            this.scriptProcessor.disconnect(this.audioContext.destination);
        }
    }

    _setMediaDevices() {
        if (this.props.saveMediaDevices) {
            this.props.saveMediaDevices(this.state.selectedAudioId, this.state.selectedVideoId, this.state.useHd);
        } else {
            this.context.setSelectedVideoInput(this.state.selectedVideoId)
            this.context.setSelectedAudioInput(this.state.selectedAudioId)
            this.context.setUseHd(this.state.useHd)
        }
    }

    changeSelection() {
        if (!this.enablePreview) {
            this._setMediaDevices()
        } else {
            this.stopVideo();
            createRTCMediaStream(this.state.selectedVideoId, this.state.selectedAudioId, this.state.useHd)
                .then((stream) => {
                    this.mediaStream = stream;
                    if (!this.props.inSettings) this.props.setStream(stream)
                    this.forceUpdate(() => {
                        this.addAudioHandle();
                        if (this.videoRef.current) this.videoRef.current.srcObject = stream
                        this._setMediaDevices()
                        this.getMediaDevices().then((devices) => {
                            const videoInputs = devices.videoInputs;
                            const audioInputs = devices.audioInputs;
                            this.setState({ videoInputs, audioInputs })
                        })
                    });
                })
                .catch((error) => {
                    console.error("Error loading media device", error);
                    this.mediaStream = null;
                    this.setState({ videoError: true });
                });
        }

    }
}



export default (DeviceConfig);
