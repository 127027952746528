import React, { useContext } from 'react';
import logo from '../../assets/img/logo.svg';
import { ReactComponent as PersonImg } from '../../assets/img/icons/person.svg';
import RecordingIndicator from '../studio/RecordingIndicator';
import { RtcContext } from '../../contexts/RtcContext';
import { isRecorder } from '../../helper/Helper';

export default function Header({ showLivestreamModal }) {
    const rtcContext = useContext(RtcContext);

    const renderRoomLimit = () => {
        if (!rtcContext.user.isAdmin || rtcContext.roomLimit < 1) return;

        const users = rtcContext.users.filter((u) => !isRecorder(u)).length;
        return <div>
            <PersonImg width={20} height={20} />
            <span>{users}/{rtcContext.roomLimit}</span>
        </div>;
    }

    const renderRecording = () => {
        if (rtcContext.isRecording()) {
            return <div className="ms-3"><RecordingIndicator /></div>;
        } else if (rtcContext.serviceToken != null) {
            return <button className="btn btn-primary btn-sm fw-bold text-white text-uppercase ms-3" onClick={showLivestreamModal}>Go Live</button>
        }
    }

    return (
        <nav className="navbar navbar-light border-bottom fixed-top" style={{ background: "#fff", flexShrink: "0" }}>
            <div className="container-fluid">
                <div className="d-flex align-items-center me-auto">
                    <img src={logo} alt="Video.Taxi Studio" className="navbar-brand me-1" />
                </div>

                {renderRoomLimit()}
                {renderRecording()}
            </div>
        </nav>
    );
}
