import { forwardRef, useState, useImperativeHandle } from 'react';
import DropIn from "braintree-web-drop-in-react";

export const PaymentForm = forwardRef(({ token }, ref) => {
    const [btInstance, setBtInstance] = useState(null);

    useImperativeHandle(ref, () => ({
        getNonce() {
            if (btInstance != null) {
                return btInstance.requestPaymentMethod().then(({ nonce }) => nonce);
            } else {
                return Promise.resolve(null);
            }
        }
    }));

    if (token != null) {
        return <DropIn
            options={{
                authorization: token,
                vaultManager: true,
                paypal: {
                    flow: 'vault',
                    displayName: 'Video.Taxi'
                }
            }}
            onInstance={setBtInstance}
        />;
    } else {
        return <div className="d-flex align-items-center flex-column justify-content-center h-100">
            <h2 className="mb-3">Summary</h2>
            <p>Great! No payment needed.</p>
        </div>;
    }
});

export default PaymentForm;
