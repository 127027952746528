import React, { useEffect } from 'react';
import { parse, stringify } from 'query-string';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Components from './Components';
import SettingsContextProvider from '../src/contexts/SettingsContext';
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from './helper/Helper';
import appSignal from "./AppSignal";
import { ErrorBoundary } from "@appsignal/react";
import RequireBrowser from "./components/general/RequireBrowser";

const UnfriendlyFallbackComponent = () => {
    useEffect(() => {
        document.location.reload();
    }, []);

    return null;
}

const studioGraphQLURL = new URL("/api/graphql", process.env.REACT_APP_STUDIO_HOST).toString();

export default function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path='/join/:id'
                    render={(props) => {
                        const queryParams = parse(props.location.search);
                        let adminToken;

                        // If we have an auth token,
                        // just remove it from the query to prevent unwanted sharing.
                        if (queryParams.t) {
                            const { t, ...rest } = queryParams;
                            adminToken = t;
                            sessionStorage.setItem(props.match.params.id, t);
                            const updatedQuery = Object.keys(rest).length > 0 ? `?${stringify(rest)}` : "";
                            window.history.replaceState(null, null, `${window.location.pathname}${updatedQuery}`);
                        } else {
                            adminToken = sessionStorage.getItem(props.match.params.id);
                        }

                        // Remove expired token
                        if (adminToken != null) {
                            const parts = adminToken.split(".");
                            if (parts.length !== 3 || JSON.parse(atob(parts[1])).exp < (new Date().getTime() / 1000)) {
                                sessionStorage.removeItem(props.match.params.id);
                                adminToken = null;
                            }
                        }
                        const httpOptions = { uri: studioGraphQLURL };
                        const client = createApolloClient(httpOptions, adminToken)

                        return (
                            <RequireBrowser>
                                <ApolloProvider client={client}>
                                    <SettingsContextProvider>
                                        <Components roomName={props.match.params.id} token={adminToken} client={client} />
                                    </SettingsContextProvider>
                                </ApolloProvider>
                            </RequireBrowser>
                        );
                    }}
                />

                <Route
                    path='/join/:id/canvas'
                    render={(props) => {
                        const httpOptions = { uri: studioGraphQLURL };
                        const client = createApolloClient(httpOptions);
                        const child = <ApolloProvider client={client}><Components roomName={props.match.params.id} justShowCanvas={true} client={client} /></ApolloProvider>;

                        if (appSignal != null) {
                            return <ErrorBoundary
                                instance={appSignal}
                                tags={{ location: document.location.href }}
                                fallback={(error) => <UnfriendlyFallbackComponent />}>
                                {child}
                            </ErrorBoundary>;
                        }

                        return child;
                    }}
                />

                <Route path='*' exact render={() => window.location.assign('https://service.video.taxi')} />
            </Switch>
        </Router>
    );
}
