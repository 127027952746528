import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationEN from './assets/locals/en/translation.json';
import translationDE from './assets/locals/de/translation.json';


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: translationEN,
            de: translationDE
        },
        lng: "de",
        saveMissing: true,
        saveMissingTo: 'all',
        saveMissingPlurals: true,
        interpolation: {
            escapeValue: false
        }
    });