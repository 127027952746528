import React, { useEffect, useState, useRef } from 'react';
import { getContrastColor } from '../Canvas';
import { CSSTransition } from 'react-transition-group';
import Textfit from 'react-textfit';

const duration = 500;

export default function StaticBanner({ background, children: banner }) {
    const [lastBanner, setLastBanner] = useState(banner);
    const nodeRef = useRef(null);

    useEffect(() => {
        if (banner && banner !== lastBanner) {
            setLastBanner(banner);
        }
    }, [banner, lastBanner]);

    const classes = {
        enter: "animate__animated",
        enterActive: "animate__fadeInLeft",
        exit: "animate__animated",
        exitActive: "animate__fadeOutLeft"
    };

    const bannerStyle = {
        backgroundColor: background,
        color: getContrastColor(background),
        "--animate-duration": `${duration}ms`
    };

    const renderBannerText = (banner) => {
        if (banner.text != null && banner.text.length < 50) {
            return <Textfit
                style={{ height: "clamp(10px, 5vw, 80px)", display: "flex", alignItems: "center" }}
                forceSingleModeWidth={false}
                mode="single"
                max={1000}>{banner.text}</Textfit>;
        }

        return banner.text;
    }

    const currentBanner = banner || lastBanner;

    return (
        <CSSTransition nodeRef={nodeRef} in={banner != null} timeout={duration} unmountOnExit classNames={classes}>
            <div ref={nodeRef} className="canvas-static-banner" style={bannerStyle}>
                {currentBanner && renderBannerText(currentBanner)}
            </div>
        </CSSTransition>
    );
}
