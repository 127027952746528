import { Modal } from "bootstrap";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

export const BaseModal = forwardRef(({ children, size, dismissable, onHide }, ref) => {
    const modalElRef = useRef();
    const modalInstanceRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useImperativeHandle(ref, () => ({
        show() {
            setIsVisible(true);
            modalInstanceRef.current.show();
        },
        hide() {
            modalInstanceRef.current.hide();
        }
    }));

    // Make sure the onHide function is using useCallback to prevent unneccessary rebuilds.
    useEffect(() => {
        modalInstanceRef.current = new Modal(modalElRef.current, { backdrop: dismissable || 'static', keyboard: dismissable === true });
        modalElRef.current.addEventListener("hidden.bs.modal", () => {
            setIsVisible(false);
            if (typeof onHide === "function") onHide();
        });
        return () => modalInstanceRef.current.dispose();
    }, [onHide, dismissable]);

    return (
        <div className="modal fade" ref={modalElRef} tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog ${size ? `modal-${size}` : ""}`}>
                <div className="modal-content">
                    {isVisible && children}
                </div>
            </div>
        </div >
    );
});
