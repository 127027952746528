import { useContext, useState, useCallback, useRef, useEffect, useMemo } from "react";
import { RtcContext } from '../../../contexts/RtcContext';
import { chatEmbedUrl, useEventListener } from "../../../helper/Helper";

const maxChatUsernameLength = 50;

export default function Chat({ rooms, onIncomingMessage, isVisible }) {
    const [selectedRoomId, setSelectedRoomId] = useState(null);
    const iframeRef = useRef();
    const rtcContext = useContext(RtcContext);
    const selectedRoom = (selectedRoomId && rooms.find((room) => room.id === selectedRoomId)) || rooms[0];

    const sendMessage = useCallback((type, data) => {
        iframeRef.current?.contentWindow?.postMessage({ type, data }, "*");
    }, [iframeRef]);

    const onWindowMessage = useCallback((event) => {
        if (typeof event.data !== "object") return;
        if (event.data.type === "incomingMessage") {
            onIncomingMessage();
        } else if (event.data.type === "confirm") {
            const result = window.confirm(event.data.message);
            sendMessage("confirm", { result })
        }
    }, [onIncomingMessage, sendMessage])

    useEffect(() => {
        if (isVisible === true) sendMessage("scrollToBottom");
    }, [isVisible, sendMessage]);

    const renderDropDown = () => {
        if (rooms.length === 1) return <span>{selectedRoom.name}</span>;

        return <div className="dropdown me-auto" style={{ minWidth: "0px" }}>
            <button style={{ textOverflow: "ellipsis", boxShadow: "none" }} className="btn btn-link text-decoration-none px-0 dropdown-toggle text-nowrap overflow-hidden w-100" data-bs-toggle="dropdown" aria-expanded="false">
                {selectedRoom.name}
            </button>
            <ul className="dropdown-menu dropdown-menu">
                {rooms.map((room) => <li key={room.id}><button onClick={() => setSelectedRoomId(room.id)} className="dropdown-item">{room.name}</button></li>)}
            </ul>
        </div>
    }

    useEventListener("message", onWindowMessage);

    const src = useMemo(() => {
        return chatEmbedUrl(selectedRoom.id, {
            username: rtcContext.user.name.substr(0, maxChatUsernameLength),
            token: selectedRoom.token
        });

        // The selectedRoom.token changes after every livestream reload, causing the chat to reload.
        // We memoize the generated url for the selectedRoomId.

        // eslint-disable-next-line
    }, [selectedRoom.id]);

    return <>
        <div className="sidebar-content-header">
            {renderDropDown()}
        </div>

        <iframe
            ref={iframeRef}
            src={src}
            className="w-100 h-100 sidebar-content-body"
            frameBorder="0"
            title={selectedRoom.name} />
    </>;
}
