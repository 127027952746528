import React, { Component, createContext } from 'react';

export const SettingsContext = createContext();

export default class SettingsContextProvider extends Component {

    state = {
        selectedVideoId: sessionStorage.getItem('videoId'),
        selectedAudioId: sessionStorage.getItem('audioId'),
        setSelectedVideoInput: this.setSelectedVideoInput.bind(this),
        setSelectedAudioInput: this.setSelectedAudioInput.bind(this),
        setUseHd: this.setUseHd.bind(this),
        useHd: localStorage.getItem('useHd') === 'true',
    }

    setSelectedAudioInput(audioId) {
        sessionStorage.setItem('audioId', audioId)
        this.setState({ selectedAudioId: audioId })
    }

    setSelectedVideoInput(videoId) {
        sessionStorage.setItem('videoId', videoId)
        this.setState({ selectedVideoId: videoId })
    }

    setUseHd(useHd) {
        localStorage.setItem('useHd', useHd)
        this.setState({ useHd: useHd })
    }


    render() {
        return (
            <SettingsContext.Provider value={this.state}>
                {this.props.children}
            </SettingsContext.Provider>
        );
    }
}
