import { useState, useRef } from 'react';
import logo from '../../assets/img/logo.svg';
import DeviceConfig from '../general/DeviceConfig';

export default function MediaDevicesCheck({ joinConference }) {
    const [name, setName] = useState("");
    const [disabled, setDisabled] = useState(true);
    const streamRef = useRef(null);

    const onSubmit = (event) => {
        event?.preventDefault();
        joinConference(name.trim(), streamRef.current);
    }

    return (
        <form className="w-100 p-3 d-flex flex-column align-items-center mx-auto justify-content-center" style={{ maxWidth: "400px", minHeight: "100%" }} onSubmit={onSubmit}>
            <img src={logo} alt="Video.Taxi Studio" className="mb-5" style={{ width: "200px" }} />

            <div className="w-100">
                <DeviceConfig
                    inSettings={false}
                    setStream={(stream) => streamRef.current = stream}
                    disableButton={setDisabled} />

                <div className="form-group mb-4">
                    <label htmlFor="input-name">Display Name</label>
                    <input
                        autoComplete="off"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        className={`form-control form-control-lg`}
                        id="input-name"
                        placeholder="Enter your name…" />
                </div>
            </div>

            <button
                className="btn btn-primary btn-lg text-uppercase"
                disabled={name.trim() === "" || disabled}>
                Here we go!
            </button>
        </form>
    );
}
