import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import plusUrl from '../../../assets/img/icons/plus.svg';
import { ReactComponent as DeleteImg } from "../../../assets/img/icons/delete.svg";
import { ReactComponent as EditImg } from '../../../assets/img/icons/edit.svg';
import { ChromePicker } from "react-color";
import * as api from "../../../api";
import { NotificationContext } from '../../../contexts/NotificationContext';
import { Center } from '../Sidebar';

export default function Theme({ roomId, client }) {
    const { loading, error, data, refetch } = useQuery(api.GET_THEME_RESOURCES, {
        client: client,
        variables: { roomId: roomId }
    });
    const [showAddTheme, setShowAddTheme] = useState(false);
    const [showRenameTheme, setShowRenameTheme] = useState(false);
    const [lastAddedThemeId, setLastAddedThemeId] = useState(null);

    if (loading) {
        return <>
            <div className="sidebar-content-header">
                <span>Themes</span>
            </div>
            <div className="sidebar-content-body">
                <Center>
                    <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2 text-muted">Themes are loading</p>
                </Center>
            </div>
        </>;
    } else if (error) {
        return <>
            <div className="sidebar-content-header">
                <span>Themes</span>
            </div>
            <div className="sidebar-content-body">
                <Center>
                    <h3>Oops, this did not work!</h3>
                    <p className="text-muted">There was an error loading your themes.</p>
                    <div className="btn btn-primary" onClick={() => refetch()}>Try Again</div>
                </Center>
            </div>
        </>;
    }

    if (showRenameTheme) {
        const currentTheme = data.themes.find((theme) => theme.id === data.room.themeId)
        return <div className="sidebar-content-body">
            <RenameTheme setShowRenameTheme={setShowRenameTheme} currentTheme={currentTheme} client={client} />
        </div>

    } else if (showAddTheme) {
        const onCreated = ({ data }) => {
            setLastAddedThemeId(data.createTheme.id);
            setShowAddTheme(false)
        };

        return <AddThemeForm client={client} onCreated={onCreated} onCancel={() => setShowAddTheme(false)} />;
    } else if (data.themes.length === 0) {
        return <>
            <div className="sidebar-content-header">
                <span>Themes</span>
            </div>
            <Center>
                <h3>You don't have any themes!</h3>
                <p className="text-muted">Create your first theme</p>
                <button className="btn btn-primary" onClick={() => setShowAddTheme(true)}>Create theme</button>
            </Center>
        </>;
    } else {
        return <ThemeManager {...data} client={client} showAddTheme={() => setShowAddTheme(true)} lastAddedThemeId={lastAddedThemeId} setLastAddedThemeId={setLastAddedThemeId} setShowRenameTheme={setShowRenameTheme} />
    }
}

function ThemeManager({ themes, room, showAddTheme, lastAddedThemeId, setLastAddedThemeId, setShowRenameTheme, client }) {
    const notificationContext = useContext(NotificationContext)
    const [switcherVisible, setSwitcherVisible] = useState(false);

    const [setCurrentTheme] = useMutation(api.UPDATE_ROOM, {
        client: client,
        updateRoom(cache, { data: { updateRoom } }) {
            const updateRoomFragment = gql(`
                fragment UpdateRoom on Room {
                    themeId
                }
            `);

            cache.writeFragment({
                id: cache.identify(updateRoom),
                fragment: updateRoomFragment,
                data: updateRoom
            });
        },
        onCompleted({ updateRoom }) {
            setSelectedThemeId(updateRoom.themeId)
        },
        onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const [updateTheme] = useMutation(api.UPDATE_THEME, {
        client: client,
        update(cache, { data: { updateTheme } }) {
            const updateThemeFragment = gql(`
                fragment ThemeUpdate on Theme {
                    color
                    logoId
                    backgroundId
                    overlayId
                }
            `);

            cache.writeFragment({
                id: cache.identify(updateTheme),
                fragment: updateThemeFragment,
                data: updateTheme
            });
        },
        onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });
    const [doDelete] = useMutation(api.DELETE_THEME, {
        client: client,
        update(cache, { data: { deleteTheme } }) {
            cache.modify({
                fields: {
                    themes(refs, { readField }) {
                        return refs.filter(
                            themeRef => deleteTheme.id !== readField('id', themeRef)
                        );
                    },
                },
            });
        }, onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const [selectedThemeId, setSelectedThemeId] = useState(room?.themeId || themes[0].id);

    useEffect(() => {
        if (lastAddedThemeId != null) {
            setCurrentTheme({ variables: { themeId: lastAddedThemeId } });
            setLastAddedThemeId(null)
        }
    }, [lastAddedThemeId, setLastAddedThemeId, setCurrentTheme]);

    const selectedTheme = themes.find((t) => t.id === selectedThemeId);

    const { loading, error, data, refetch } = useQuery(api.GET_THEME, {
        variables: { id: selectedTheme.id }
    });

    const onSelectTheme = (id) => {
        setCurrentTheme({ variables: { themeId: id } });
        setSwitcherVisible(false);
    };

    const onAddTheme = () => {
        setSwitcherVisible(false);
        showAddTheme();
    };

    async function onDelete() {
        if (!window.confirm("Are you sure that you want to delete this theme?")) return;

        const nextTheme = themes.find((t) => t.id !== selectedThemeId);
        if (nextTheme) {
            await setCurrentTheme({ variables: { themeId: nextTheme.id } });
        }
        doDelete({ variables: { id: selectedTheme.id } });
    }

    const renderDropdown = () => {
        const popover = {
            position: 'absolute',
            zIndex: '2',
        }

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px'
        }

        return (
            <div className="dropdown me-auto" style={{ minWidth: "0px" }}>
                <button style={{ textOverflow: "ellipsis", boxShadow: "none" }} className="btn btn-link text-decoration-none px-0 dropdown-toggle text-nowrap overflow-hidden w-100" onClick={() => setSwitcherVisible(!switcherVisible)} aria-expanded="false">
                    {selectedTheme.name}
                </button>

                {switcherVisible && <div style={popover}>
                    <div style={cover} onClick={() => setSwitcherVisible(false)} /><ul className="dropdown-menu show">
                        {themes.map((t) => <li key={t.id}><button className="dropdown-item" onClick={() => onSelectTheme(t.id)}>{t.name}</button></li>)}
                        <li><button className="dropdown-item" onClick={onAddTheme}>+ Add a new preset</button></li>
                    </ul>
                </div>}
            </div>
        );
    }

    const renderTheme = (data, loading, error, refetch) => {

        if (loading) {
            return <Center>
                <div className="spinner-grow text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-2 text-muted">Theme are loading</p>
            </Center>
        } else if (error) {
            return <Center>
                <h3>Oops, this did not work!</h3>
                <p className="text-muted">There was an error loading your theme.</p>
                <div className="btn btn-primary" onClick={() => refetch()}>Try Again</div>
            </Center>

        }
        const theme = data.theme;

        return <>
            <Group title="Color">
                <Color
                    current={theme.color}
                    onChange={(color) => updateTheme({ variables: { id: theme.id, color: color } })}
                />
            </Group>

            <Group title="Logo">
                <Logos
                    client={client}
                    theme={theme}
                    onChange={(id) => updateTheme({ variables: { id: theme.id, logoId: id } })} />
            </Group>

            <Group title="Overlay">
                <Overlays
                    client={client}
                    theme={theme}
                    onChange={(id) => updateTheme({ variables: { id: theme.id, overlayId: id } })} />
            </Group>

            <Group title="Background">
                <Backgrounds
                    client={client}
                    theme={theme}
                    onChange={(id) => updateTheme({ variables: { id: theme.id, backgroundId: id } })} />
            </Group>

            <Group title="Settings">
                <ThemeSettings current={theme.showNames}
                    onChange={(showNames) => updateTheme({ variables: { id: theme.id, showNames: showNames } })} />
            </Group>

        </>
    }

    return <>
        <div className="sidebar-content-header" key={`header-${selectedThemeId}`}>
            {renderDropdown()}
            {selectedThemeId && <button className="btn btn-sm btn-primary ms-1 text-nowrap d-flex align-items-center" onClick={() => setShowRenameTheme(true)}><EditImg width={19} height={19} /></button>}
            <button className="btn btn-sm btn-danger ms-1 text-nowrap d-flex align-items-center" onClick={onDelete}><DeleteImg width={19} height={19} /></button>
        </div>

        <div className="sidebar-content-body" key={`body-${selectedThemeId}`}>
            {renderTheme(data, loading, error, refetch)}
        </div>
    </>;
}

function Group({ title, children }) {
    return (
        <div className="border-bottom ps-3 pe-5 py-3">
            <h6>{title}</h6>
            {children}
        </div>
    );
}

function Color({ current, onChange }) {
    const [currentColor, setCurrentColor] = useState(current);
    const [showPicker, setShowPicker] = useState(false);

    const innerStyle = {
        height: 0,
        paddingBottom: "100%",
        backgroundColor: currentColor,
        borderRadius: "4px"
    };

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
    }

    return (
        <div className="row g-2">
            <div className="col-4" onClick={() => setShowPicker(true)}>
                <div style={innerStyle}>
                    {showPicker && <div style={popover}>
                        <div style={cover} onClick={(e) => { e.stopPropagation(); setShowPicker(false) }} />
                        <ChromePicker
                            disableAlpha
                            color={currentColor}
                            onChange={(c) => setCurrentColor(c.hex)}
                            onChangeComplete={(c) => { setCurrentColor(c.hex); onChange(c.hex) }} />
                    </div>}
                </div>
            </div>
        </div>
    );
}

function AddThemeForm({ onCreated, onCancel, client }) {
    const notificationContext = useContext(NotificationContext)

    const [addTheme, { loading }] = useMutation(api.ADD_THEME, {
        client: client,
        update(cache, { data: { createTheme } }) {
            cache.modify({
                fields: {
                    themes(existingThemes = []) {
                        const newThemeRef = cache.writeFragment({
                            data: createTheme,
                            fragment: gql(`
                                fragment NewTheme on Theme {
                                    id
                                    themeId
                                    name
                                    color
                                }
                            `)
                        });

                        return [...existingThemes, newThemeRef];
                    }
                }
            });
        },
        onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });
    const [name, setName] = useState("");

    const reset = (event) => {
        event.preventDefault();
        setName("");
        onCancel();
    };

    const submit = (event) => {
        event.preventDefault();
        addTheme({ variables: { name: name } }).then(onCreated);
        setName("");
    };

    return <>
        <div className="sidebar-content-header">
            <span>Themes</span>
        </div>
        <div className="sidebar-content-body">
            <Center>
                <form onSubmit={submit}>
                    <h3>Create a new preset</h3>
                    <p className="text-muted">What's the name of your preset?</p>
                    <input type="text" autoFocus placeholder="Enter name" className="form-control mb-2 text-center" value={name} onChange={(e) => setName(e.currentTarget.value)} />
                    <button type="submit" className="btn btn-primary w-100 mb-2" disabled={!name.length || loading}>Create</button>
                    <button onClick={reset} className="btn btn-light w-100" disabled={loading}>Cancel</button>
                </form>
            </Center>
        </div>
    </>;
}

const cardBorderStyle = {
    borderRadius: "5px",
    padding: "3px",
    position: "relative"
};

const cardInnerStyle = {
    borderRadius: "4px",
    height: 0,
    backgroundColor: "#D5D5D5",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
};

function DeleteButton({ onClick }) {
    return (
        <button onClick={onClick} className="position-absolute btn btn-danger rounded-circle p-0 d-flex align-items-center justify-content-center" style={{ top: "8px", right: "8px", width: "24px", height: "24px" }}>
            <DeleteImg width="16" height="16" />
        </button>
    );
}

function Logos({ theme, onChange, client }) {
    const notificationContext = useContext(NotificationContext)

    const [doUpload, { loading }] = useMutation(api.CREATE_LOGO, {
        client: client,
        update(cache, { data: { createLogo } }) {

            const { logos } = cache.readFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_LOGOS,
                fragmentName: "logoArray"
            });

            cache.writeFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_LOGOS,
                fragmentName: "logoArray",
                data: { logos: [...logos, createLogo] }
            });
        },
        onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });
    const [doDelete] = useMutation(api.DELETE_LOGO, {
        client: client,
        update(cache, { data: { deleteLogo } }) {
            const { logos } = cache.readFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_LOGOS,
                fragmentName: "logoArray"
            });

            cache.writeFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_LOGOS,
                fragmentName: "logoArray",
                data: { logos: logos.filter((l) => l.id !== deleteLogo.id) }
            });
        },
        onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const onUpload = (event) => {
        event.preventDefault();
        if (event.currentTarget.files.length === 0) return;

        doUpload({ variables: { file: event.currentTarget.files[0], themeId: theme.id } });
        event.currentTarget.value = "";
    }

    const onClick = (id) => onChange(theme.logoId === id ? null : id);

    const onDelete = (event, id) => {
        event.stopPropagation();

        doDelete({ variables: { id: id } });
    }

    const renderLogo = (logo) => {
        const innerStyleWithImg = {
            ...cardInnerStyle,
            paddingBottom: "100%",
            backgroundImage: `url(${logo.urls.hd})`,
            backgroundSize: "75%"
        };

        return (
            <div key={logo.id} className="col-4">
                <div className={logo.id === theme.logoId ? "bg-primary" : "bg-white"} onClick={() => onClick(logo.id)} style={cardBorderStyle}>
                    <div style={innerStyleWithImg}></div>
                    <DeleteButton onClick={(e) => onDelete(e, logo.id)} />
                </div>
            </div>
        );
    }

    const renderUploader = () => {
        let fileInput;

        const innerStyleWithAdd = {
            ...cardInnerStyle,
            paddingBottom: "100%",
            backgroundImage: `url(${plusUrl})`
        }

        return (
            <div className="col-4">
                <div className={loading ? "opacity-25" : null} style={cardBorderStyle}>
                    <div className="bg-white border overflow-hidden position-relative" style={innerStyleWithAdd} onClick={() => fileInput.click()}>
                        <input type="file" accept="image/*" ref={(ref) => fileInput = ref} onChange={onUpload} disabled={loading} className="invisible position-absolute" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="row g-2">
            {theme.logos.map(renderLogo)}
            {renderUploader()}
        </div>
    );
}

function Backgrounds({ onChange, theme, client }) {
    const notificationContext = useContext(NotificationContext)

    const [doUpload, { loading }] = useMutation(api.CREATE_BACKGROUND, {
        client: client,
        update(cache, { data: { createBackground } }) {

            const { backgrounds } = cache.readFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_BACKGROUNDS,
                fragmentName: "backgroundArray"
            });

            cache.writeFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_BACKGROUNDS,
                fragmentName: "backgroundArray",
                data: { backgrounds: [...backgrounds, createBackground] }
            });
        },
        onError(error) {
            notificationContext.error("Error while creating background", error.message);
        }
    });

    const [doDelete] = useMutation(api.DELETE_BACKGROUND, {
        client: client,
        update(cache, { data: { deleteBackground } }) {

            const { backgrounds } = cache.readFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_BACKGROUNDS,
                fragmentName: "backgroundArray"
            })

            cache.writeFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_BACKGROUNDS,
                fragmentName: "backgroundArray",
                data: { backgrounds: backgrounds.filter((b) => b.id !== deleteBackground.id) }
            });
        },
        onError(error) {
            notificationContext.error("Error while deleting background", error.message);
        }
    });

    const [doUpdate] = useMutation(api.UPDATE_BACKGROUND, {
        client: client,
        update(cache, { data: { updateBackground } }) {
            const updateBackgroundFragment = gql(`
                fragment BackgroundUpdate on Background {
                    name
                }
            `);

            cache.writeFragment({
                id: cache.identify(updateBackground),
                fragment: updateBackgroundFragment,
                data: updateBackground
            });
        },
        onError(error) {
            notificationContext.error("Error while updating background", error.message);
        },
    });

    const onUpload = (event) => {
        event.preventDefault();
        if (event.currentTarget.files.length === 0) return;

        doUpload({ variables: { file: event.currentTarget.files[0], themeId: theme.id } });
        event.currentTarget.value = "";
    }

    const onClick = (id) => onChange(theme.backgroundId === id ? null : id);

    const onDelete = (event, id) => {
        event.stopPropagation();
        doDelete({ variables: { id: id } });
    }

    const onUpdate = (event, item) => {
        const value = event.currentTarget.value;
        if (value === "" || value === item.name) return;

        doUpdate({ variables: { id: item.id, name: value } });
    }

    const renderUploader = () => {
        let fileInput;

        const innerStyleWithAdd = {
            ...cardInnerStyle,
            paddingBottom: "56.25%",
            backgroundImage: `url(${plusUrl})`
        }

        return (
            <div className="col-6">
                <div className={loading ? "opacity-25" : null} style={cardBorderStyle}>
                    <div className="bg-white border overflow-hidden position-relative" style={innerStyleWithAdd} onClick={() => fileInput.click()}>
                        <input type="file" accept="image/*" ref={(ref) => fileInput = ref} onChange={onUpload} disabled={loading} className="invisible position-absolute" />
                    </div>
                </div>
            </div>
        );
    }

    const renderItem = (item) => {
        const innerStyleWithImg = {
            ...cardInnerStyle,
            paddingBottom: "56.25%",
            backgroundImage: `url(${item.urls.hd})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        };

        return (
            <div key={item.id} className="col-6">
                <div className={item.id === theme.backgroundId ? "bg-primary" : "bg-white"} onClick={() => onClick(item.id)} style={cardBorderStyle}>
                    <div style={innerStyleWithImg}></div>
                    <DeleteButton onClick={(e) => onDelete(e, item.id)} />
                </div>
                <input type="text" defaultValue={item.name} className="form-control form-control-sm form-control-no-outline w-100 border-0 py-0 px-0 text-center text-muted" style={{ minHeight: 0 }} onBlur={(e) => onUpdate(e, item)} />
            </div>
        );
    }

    return (
        <div className="row g-2">
            {theme.backgrounds.map(renderItem)}
            {renderUploader()}
        </div>
    );
}

function Overlays({ onChange, theme, client }) {
    const notificationContext = useContext(NotificationContext)

    const [doUpload, { loading }] = useMutation(api.CREATE_OVERLAY, {
        client: client,
        update(cache, { data: { createOverlay } }) {

            const { overlays } = cache.readFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_OVERLAYS,
                fragmentName: "overlayArray"
            });

            cache.writeFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_OVERLAYS,
                fragmentName: "overlayArray",
                data: { overlays: [...overlays, createOverlay] }
            });

        },
        onError(error) {
            notificationContext.error("Error while creating overlay", error.message);
        }
    });
    const [doDelete] = useMutation(api.DELETE_OVERLAY, {
        client: client,
        update(cache, { data: { deleteOverlay } }) {
            const { overlays } = cache.readFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_OVERLAYS,
                fragmentName: "overlayArray"
            });

            cache.writeFragment({
                id: cache.identify(theme),
                fragment: api.FRAGMENT_OVERLAYS,
                fragmentName: "overlayArray",
                data: { overlays: overlays.filter((o) => o.id !== deleteOverlay.id) }
            });
        }, onError(error) {
            notificationContext.error("Error while deleting overlay", error.message);
        }
    });

    const [doUpdate] = useMutation(api.UPDATE_OVERLAY, {
        client: client,
        update(cache, { data: { updateOverlay } }) {
            const updateOverlayFragment = gql(`
                fragment OverlayUpdate on Overlay {
                    name
                }
            `);

            cache.writeFragment({
                id: cache.identify(updateOverlay),
                fragment: updateOverlayFragment,
                data: updateOverlay
            });
        },
        onError(error) {
            notificationContext.error("Error while updating overlay", error.message);
        },
    });

    const onUpload = (event) => {
        event.preventDefault();
        if (event.currentTarget.files.length === 0) return;

        doUpload({ variables: { file: event.currentTarget.files[0], themeId: theme.id } });
        event.currentTarget.value = "";
    }

    const onClick = (id) => onChange(theme.overlayId === id ? null : id);

    const onDelete = (event, id) => {
        event.stopPropagation();
        doDelete({ variables: { id: id } });
    }

    const onUpdate = (event, item) => {
        const value = event.currentTarget.value;
        if (value === "" || value === item.name) return;

        doUpdate({ variables: { id: item.id, name: value } });
    }

    const renderUploader = () => {
        let fileInput;

        const innerStyleWithAdd = {
            ...cardInnerStyle,
            paddingBottom: "56.25%",
            backgroundImage: `url(${plusUrl})`
        }

        return (
            <div className="col-6">
                <div className={loading ? "opacity-25" : null} style={cardBorderStyle}>
                    <div className="bg-white border overflow-hidden position-relative" style={innerStyleWithAdd} onClick={() => fileInput.click()}>
                        <input type="file" accept="image/png" ref={(ref) => fileInput = ref} onChange={onUpload} disabled={loading} className="invisible position-absolute" />
                    </div>
                </div>
            </div>
        );
    }

    const renderItem = (item) => {
        const innerStyleWithImg = {
            ...cardInnerStyle,
            paddingBottom: "56.25%",
            backgroundImage: `url(${item.urls.hd})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
        };

        return (
            <div key={item.id} className="col-6">
                <div className={item.id === theme.overlayId ? "bg-primary" : "bg-white"} onClick={() => onClick(item.id)} style={cardBorderStyle}>
                    <div style={innerStyleWithImg}></div>
                    <DeleteButton onClick={(e) => onDelete(e, item.id)} />
                </div>
                <input type="text" defaultValue={item.name} className="form-control form-control-sm form-control-no-outline w-100 border-0 py-0 px-0 text-center text-muted" style={{ minHeight: 0 }} onBlur={(e) => onUpdate(e, item)} />
            </div>
        );
    }

    return (
        <div className="row g-2">
            {theme.overlays.map(renderItem)}
            {renderUploader()}
        </div>
    );
}

function RenameTheme({ setShowRenameTheme, currentTheme, client }) {
    const [renameThemeText, setRenameThemeText] = useState(currentTheme.name);
    const notificationContext = useContext(NotificationContext)
    const [updateTheme] = useMutation(api.UPDATE_THEME, {
        client: client,
        update(cache, { data: { updateTheme } }) {
            const renameThemeFragment = gql(`
                fragment RenameTheme on Theme {
                    name
                }
            `);

            cache.writeFragment({
                id: cache.identify(updateTheme),
                fragment: renameThemeFragment,
                data: updateTheme
            });
        },
        onCompleted() {
            setShowRenameTheme(false)
        },
        onError(error) {
            const message = { variant: "danger", headingText: "Error", bodyText: error.message, withTimer: true, timer: 5000 }
            notificationContext.showMessage(message)
        }
    });

    const submit = (event) => {
        event.preventDefault();
        updateTheme({ variables: { id: currentTheme.id, name: renameThemeText } })
    }
    return <Center>
        <div className="w-100 px-3">
            <form onSubmit={submit}>
                <h3>Rename preset</h3>
                <input type="text" autoFocus placeholder="Enter theme" className="form-control mb-2 text-center" value={renameThemeText} onChange={(e) => setRenameThemeText(e.currentTarget.value)} />
                <button type="submit" className="btn btn-primary w-100 mb-2" disabled={!renameThemeText.length}>Rename</button>
                <button type="button" onClick={() => setShowRenameTheme(false)} className="btn btn-light w-100"> Close</button>
            </form>
        </div>
    </Center>
}

function ThemeSettings({ current, onChange }) {
    const [showNames, setShowNames] = useState(current)


    return <div className="form-check form-switch">
        <input checked={showNames} onChange={(e) => { setShowNames(e.target.checked); onChange(e.target.checked) }} className="form-check-input" type="checkbox" id="nameSwitcher" />
        <label className="form-check-label fw-normal" htmlFor="nameSwitcher">
            Display name tags of users
        </label>
    </div>
}
