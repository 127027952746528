import React, { useContext, useRef, useEffect, useState } from 'react';
// import { useStreamAudioLevel } from 'stream-audio-level-hooks';
import { RtcContext } from '../../contexts/RtcContext';
import { streamOfParticipant, isScreenShare, isStreamIn, isRecorder } from '../../helper/Helper';
import { ReactComponent as PersonImg } from '../../assets/img/icons/person.svg';
// import { ReactComponent as Person2Img } from '../../assets/img/icons/person2.svg';
import { ReactComponent as ScreenImg } from '../../assets/img/icons/screen.svg';
import { ReactComponent as MicImg } from '../../assets/img/icons/mic.svg';
import { ReactComponent as MicMutedImg } from '../../assets/img/icons/mic-mute.svg';
import { ReactComponent as StreamingInsImage } from '../../assets/img/icons/film-outline.svg';

export default function Sources() {
	const rtcContext = useContext(RtcContext);

	const renderParticipant = (participant) => {
		const stream = streamOfParticipant(rtcContext.streams, participant.id);
		const mySelf = participant.id === rtcContext.user.id;
		const visible = rtcContext.visibleParticipants.includes(participant.id);
		const toggleFn = visible ? rtcContext.removeParticipant : rtcContext.addParticipant;
		const isPromoted = rtcContext.visibleParticipants[0] === participant.id;
		const volume = !mySelf && rtcContext.user.isAdmin && stream != null ? rtcContext.getVolume(stream.id) : null;

		const actions = <>
			<button className="btn btn-sm btn-primary text-uppercase" onClick={toggleFn.bind(this, participant.id, false)}>
				{visible ? "Remove" : "Add"}
			</button>
			<button className="btn btn-sm btn-secondary text-uppercase ms-2" disabled={isPromoted} onClick={rtcContext.addParticipant.bind(this, participant.id, true)}>
				<PersonImg width={18} height={18} />
			</button>
		</>;

		return <Source
			key={participant.id}
			icon={<PersonImg />}
			name={mySelf ? "Myself" : participant.userId}
			visible={visible}
			actions={rtcContext.user.isAdmin ? actions : null}
			volume={volume}
			setVolume={rtcContext.setVolume.bind(this, stream?.id)}
			stream={stream} />;
	}

	const renderStream = (stream) => {
		const user = rtcContext.users.find((u) => u.id === stream.origin);
		const mySelf = user?.id === rtcContext.user.id;
		const visible = rtcContext.visibleStream === stream.id;
		const volume = rtcContext.user.isAdmin && !isScreenShare(stream) ? rtcContext.getVolume(stream.id) : null;

		const actions = <>
			<button className="btn btn-sm btn-primary text-uppercase" onClick={rtcContext.setVisibleStream.bind(this, visible ? null : stream.id)}>
				{visible ? "Remove" : "Add"}
			</button>
		</>;

		return <Source
			key={stream.id}
			icon={isScreenShare(stream) ? <ScreenImg /> : <StreamingInsImage width={24} height={24} />}
			name={isScreenShare(stream) ? (user == null || mySelf ? "Screenshare" : `Screen of ${user.userId}`) : "Stream"}
			visible={visible}
			actions={rtcContext.user.isAdmin ? actions : null}
			volume={volume}
			setVolume={rtcContext.setVolume.bind(this, stream?.id)}
			stream={stream} />;
	}

	const participants = rtcContext.users
		.filter((u) => rtcContext.user.isAdmin || u.id === rtcContext.user.id)
		.filter((u) => !isRecorder(u));

	const streams = rtcContext.streams
		.filter((s) => rtcContext.user.isAdmin || s.origin === rtcContext.user.id)
		// When calling conference.leave the participant leaves before the stream ends. This causes the stream to land in the bottom section.
		// In order to prevent this we hide all streams that are not screen shares or video stream sfrom the server (origin === admin).
		// .filter((s) => isScreenShare(s) || rtcContext.users.find((u) => u.id === s.origin) == null);
		.filter((s) => isScreenShare(s) || isStreamIn(s));

	return <>
		<SourceSection
			title="Participants"
			icon={<PersonImg />}>

			{participants.map(renderParticipant)}
		</SourceSection>

		<SourceSection
			title="Screenshares &amp; Streams"
			icon={<ScreenImg />}>

			{streams.map(renderStream)}
		</SourceSection>
	</>;
}

function SourceSection(props) {
	if (props.children.length === 0) return null;

	const visible = props.children.filter((c) => c.props.visible).length;

	return (
		<div className="container my-3">
			{props.icon}
			<span className="p-2 font-weight-bold">{props.title}</span>
			<small>({visible}/{props.children.length})</small>
			<div className="row mt-1">
				{props.children.map((child, index) => <div key={index} className="mb-3 col-6 col-md-6 col-lg-4 col-xl-3">{child}</div>)}
			</div>
		</div>
	);
}

function Source({ visible, stream, actions, icon, name, volume, setVolume }) {
	const [isHovering, setIsHovering] = useState(false);

	return (
		<div className={`card source-item position-relative ${visible ? "opacity-100" : "opacity-50"}`} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
			<div className={`ratio ratio-16x9 bg-dark position-relative ${visible ? "vt-inner-border" : ""}`}>
				{stream && <SourceVideo>{stream.mediaStream}</SourceVideo>}
				{/* {stream && <SourceAudio>{stream.mediaStream}</SourceAudio>} */}
				{actions && <div className="position-absolute d-flex align-items-center justify-content-center invisible source-item-actions w-100 h-100">
					{actions}
				</div>}
			</div>

			<div className={`d-flex align-items-center ${visible ? 'bg-primary' : ''}`} style={{ height: "40px", lineHeight: "40px" }}>
				<div className={`text-truncate px-2 w-100`}>
					{icon} <small>{name}</small>
				</div>

				{volume != null && (isHovering || volume === 0) && <VerticalVolume volume={volume} setVolume={setVolume} />}
			</div>
		</div>
	);
}

const VerticalVolume = ({ volume, setVolume }) => {
	const [isHovering, setIsHovering] = useState(false);

	const sliderStyle = {
		WebkitAppearance: "slider-vertical",
		writingMode: "bt-lr"
	}

	return <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
		{isHovering && <div className="position-absolute py-3" style={{ bottom: 40, top: 0, right: 0, width: 50 }}>
			<div className="vt-slider">
				<div className="vt-slider-track" style={{ height: `${volume * 100}%` }}></div>

				<input
					type="range"
					orient="vertical"
					min={0}
					max={1}
					step={0.05}
					value={volume}
					onChange={(e) => setVolume(e.currentTarget.value)}
					style={sliderStyle} />
			</div>
		</div>}

		<button className="btn btn-no-outline" onClick={setVolume.bind(this, volume > 0 ? 0 : 1)}>
			{volume > 0 ? <MicImg /> : <MicMutedImg className="text-danger" />}
		</button>
	</div>;
}

function SourceVideo({ children }) {
	const ref = useRef(null);

	useEffect(() => {
		if (!ref.current) return;
		ref.current.srcObject = children
	}, [children])

	return <video
		ref={ref}
		className="position-absolute"
		style={{ objectFit: "cover", objectPosition: "50% 50%", width: "100%", height: "100%" }}
		muted={true}
		playsInline
		autoPlay={true} />;
}

// function SourceAudio({ children }) {
// 	const level = useStreamAudioLevel(children);
// 	const mappedLevel = mapRange(level, 0, 200, 0, 20);

// 	return (
// 		<div className="p-absolute w-100 h-100 d-flex bg-dark flex-column align-items-center justify-content-center">
// 			<div className="bg-black rounded-circle" style={{ width: 65, height: 65, border: "3px solid var(--bs-primary)", boxShadow: `0 0 0px ${mappedLevel}px rgba(250, 183, 0, .3)`, transition: "box-shadow 50ms linear" }}>
// 				<Person2Img color="var(--bs-primary)" width="100%" height="100%" style={{ padding: "20%" }} />
// 			</div>
// 		</div >
// 	);
// }

// const mapRange = (value, inMin, inMax, outMin, outMax) => {
// 	return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
// }
