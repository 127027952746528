import { gql } from '@apollo/client';

export const GET_THEME_RESOURCES = gql(`
    query($roomId: ID!) {
        room(id: $roomId) {
            id
            themeId
        }

        themes {
            id
            name
        }
    }
`);


export const FRAGMENT_CURRENT_ASSETS = gql(`
fragment currentAssets on Theme {
    logo {
        id
        urls {
            fullhd
            hd
            uhd
            wqhd
        }
    }
    background {
        id
        name
        urls {
            fullhd
            hd
            uhd
            wqhd
        }
    }
    overlay {
        id
        name
        urls {
            fullhd
            hd
            uhd
            wqhd
        }
    }
}
`);

export const FRAGMENT_LOGOS = gql(`
fragment logoArray on Theme {
    logos {
        id
        urls {
            fullhd
            hd
            uhd
            wqhd
        }
    }
}
`);

export const FRAGMENT_OVERLAYS = gql(`
fragment overlayArray on Theme {
    overlays {
        id
        name
        urls {
            fullhd
            hd
            uhd
            wqhd
        }
    }
}
`);

export const FRAGMENT_BACKGROUNDS = gql(`
fragment backgroundArray on Theme {
    backgrounds {
        id
        name
        urls {
            fullhd
            hd
            uhd
            wqhd
        }
    }
}
`);

export const GET_ROOM = gql(`
    ${FRAGMENT_CURRENT_ASSETS.loc.source.body}

    query($id: ID!) {
        room(id: $id) {
            id
            themeId
            theme {
                id
                color
                showNames
                ...currentAssets
            }
            scrollingBannerId
            scrollingBanner {
                id
                text
            }
            staticBannerId
            staticBanner {
                id
                text
            }
        }
    }
`);

export const GET_THEME = gql(`
${FRAGMENT_LOGOS.loc.source.body}
${FRAGMENT_BACKGROUNDS.loc.source.body}
${FRAGMENT_OVERLAYS.loc.source.body}

query($id: ID!) {
    theme(id: $id) {
        id
        color
        logoId
        backgroundId
        overlayId
        showNames
        ...logoArray
        ...backgroundArray
        ...overlayArray
    }
}
`);

export const UPDATE_THEME = gql(`
    ${FRAGMENT_CURRENT_ASSETS.loc.source.body}
    mutation($id: ID!, $color: String, $logoId: ID, $backgroundId: ID, $overlayId: ID, $name: String, $showNames: Boolean) {
        updateTheme(id: $id, color: $color, logoId: $logoId, backgroundId: $backgroundId, overlayId: $overlayId, name: $name, showNames: $showNames) {
            id
            name
            color
            showNames
            logoId
            backgroundId
            overlayId
            ...currentAssets
        }
    }
`);


export const ADD_THEME = gql(`
    ${FRAGMENT_CURRENT_ASSETS.loc.source.body}
    mutation($name: String!) {
        createTheme(name: $name) {
            id
            name
            color
            logoId
            backgroundId
            overlayId
            showNames
            ...currentAssets
        }
    }
`);

export const DELETE_THEME = gql(`
    mutation($id: ID!) {
        deleteTheme(id: $id) {
            id
        }
    }
`);

export const CREATE_BACKGROUND = gql(`
    mutation($file: Upload!, $themeId: ID!) {
        createBackground(file: $file, themeId: $themeId) {
            id
            name
            themeId
            urls {
                fullhd
                hd
                uhd
                wqhd
            }
        }
    }
`);

export const DELETE_BACKGROUND = gql(`
    mutation($id: ID!) {
        deleteBackground(id: $id) {
            id
        }
    }
`);

export const UPDATE_BACKGROUND = gql(`
    mutation($id: ID!, $name: String!) {
        updateBackground(id: $id, name: $name) {
            id
            name
            themeId
            urls {
                fullhd
                hd
                uhd
                wqhd
            }
        }
    }
`);

export const CREATE_OVERLAY = gql(`
    mutation($file: Upload!, $themeId: ID!) {
        createOverlay(file: $file, themeId: $themeId) {
            id
            name
            themeId
            urls {
                fullhd
                hd
                uhd
                wqhd
            }
        }
    }
`);

export const UPDATE_OVERLAY = gql(`
    mutation($id: ID!, $name: String!) {
        updateOverlay(id: $id, name: $name) {
            id
            name
            themeId
            urls {
                fullhd
                hd
                uhd
                wqhd
            }
        }
    }
`);

export const DELETE_OVERLAY = gql(`
    mutation($id: ID!) {
        deleteOverlay(id: $id) {
            id
        }
    }
`);

export const CREATE_LOGO = gql(`
    mutation($file: Upload!, $themeId: ID!) {
        createLogo(file: $file, themeId: $themeId) {
            id
            themeId
            urls {
                fullhd
                hd
                uhd
                wqhd
            }
        }
    }
`);

export const DELETE_LOGO = gql(`
    mutation($id: ID!) {
        deleteLogo(id: $id) {
            id
        }
    }
`);

export const GET_BANNERS = gql(`
    query {
        banners {
            id
            roomId
            text
        }
    }
`);

export const ADD_BANNER = gql(`
    mutation($text: String!) {
        createBanner(text: $text) {
            id
            roomId
            text
        }
    }
`);

export const DELETE_BANNER = gql(`
    mutation($id: ID!) {
        deleteBanner(id: $id) {
            id
        }
    }
`);

export const UPDATE_BANNER = gql(`
    mutation($id: ID!, $text: String!) {
        updateBanner(id: $id, text: $text){
            id
            text
        }
    }
`);

export const UPDATE_ROOM = gql(`
    ${FRAGMENT_CURRENT_ASSETS.loc.source.body}

    mutation($scrollingBannerId: ID, $staticBannerId: ID, $themeId: ID) {
        updateRoom(scrollingBannerId: $scrollingBannerId, staticBannerId: $staticBannerId, themeId: $themeId){
            id
            themeId
            theme {
                id
                color
                logoId
                overlayId
                backgroundId
                ...currentAssets
            }
            staticBannerId
            staticBanner {
                id
                text
            }
            scrollingBannerId
            scrollingBanner {
                id
                text
            }
        }
    }
`);

export const START_ROOM = gql(`
mutation {
    startRoom
  }
`);

export const JOIN_ROOM = gql(`
mutation($name: String!, $roomId: ID!, $role: String!) {
    joinRoom(name: $name, roomId: $roomId, role: $role) {
        token
        limit
    }
}
`);




export const FRAGMENT_BASKET_FIELDS = gql(`
fragment basketFields on Basket {
    fromWallet {
        amount
        currency
    }

    items {
        name
        details
        netAmount {
            amount
            currency
        }
    }

    discounts {
        name
        amount {
            amount
            currency
        }
    }

    netAmount {
        amount
        currency
    }

    taxAmount {
        amount
        currency
    }

    toPay {
        amount
        currency
    }

    oldPayment {
        netAmount {
            amount
            currency
        }

        taxAmount {
            amount
            currency
        }
    }

    totalAmount {
        amount
        currency
    }
}
`);

export const ESTIMATE_LIVESTREAM_CREATE = gql(`
${FRAGMENT_BASKET_FIELDS.loc.source.body}

query ($attributes: LivestreamCreateAttributes!, $timezone: Timezone!) {
    estimation: estimateLivestreamCreate(attributes: $attributes, timezone: $timezone) {
        token
        errors {
            message
            path
        }
        basket {
            ...basketFields
        }
    }
    }
`);

export const CREATE_LIVESTREAM = gql(`
mutation ($attributes: LivestreamCreateAttributes!, $paymentNonce: String, $timezone: Timezone!) {
    createLivestream(attributes: $attributes, paymentNonce: $paymentNonce, timezone: $timezone) {
        embedSnippet
        chatToken
        embedUrl
        chatEnabled
        description
        duration
        expectedVisitors
        id
        inputUrl
        name
        startsAt
        state
    }
}
`);

export const GET_LIVESTREAM = gql(`
query ($id: String) {
    livestream(id: $id) {
        id
        state
        actualState
        metrics {
            maxCcUsers
            traffic
            views
            ccUsers
        }
    }
}

`)

export const GET_LIVESTREAMS = gql(`
query ($allowedStates: [LivestreamState]) {
    livestreams(allowedStates: $allowedStates) {
        embedSnippet
        chatToken
        embedUrl
        chatEnabled
        description
        duration
        expectedVisitors
        id
        inputUrl
        name
        startsAt
        state
    }
}
`);

export const CANCEL_LIVESTREAM = gql(`
mutation ($id: String!) {
    cancelLivestream(id: $id) {
        embedSnippet
        chatToken
        embedUrl
        chatEnabled
        description
        duration
        expectedVisitors
        id
        inputUrl
        name
        startsAt
        state
    }
}
`);

export const EXTEND_LIVESTREAM = gql(`
mutation ($duration: Int, $id: String!, $paymentNonce: String, $timezone: Timezone!) {
    extendLivestream(duration: $duration, id: $id, paymentNonce: $paymentNonce, timezone: $timezone) {
        embedSnippet
        chatToken
        embedUrl
        chatEnabled
        description
        duration
        expectedVisitors
        id
        inputUrl
        name
        startsAt
        state
    }
}
`);

export const ESTIMATE_LIVESTREAM_EXTENSION = gql(`
${FRAGMENT_BASKET_FIELDS.loc.source.body}

query($id: String!, $duration: Int!, $timezone: Timezone!) {
    estimation: estimateLivestreamExtension(id: $id, newDuration: $duration, timezone: $timezone) {
        token
        errors {
            message
            path
        }
        basket {
            ...basketFields
        }
    }
}
`);

export const STOP_STREAM_IN = gql(`
mutation($id: String!) {
    stopStreamIn(id: $id)
}
`);

export const START_STREAM_IN = gql(`
mutation($url: String!, $transportProtocol: String!, $bufferSize: Int!) {
    startStreamIn(url: $url, transportProtocol: $transportProtocol, bufferSize: $bufferSize)
}
`);

export const CANCEL_LIVESTREAM_UPDATE_CACHE = (cache, { data: { cancelLivestream } }) => {
    cache.modify({
        fields: {
            livestreams(refs, { readField }) {
                return refs.filter(
                    stream => cancelLivestream.id !== readField('id', stream));

            },
        },
    });
}
export const EXTEND_LIVESTREAM_UPDATE_CACHE = (cache, { data }) => {
    const extendsLivestreamFragment = gql(`
    fragment NewLivestream on Livestream {
        embedSnippet
        embedUrl
        chatToken
        chatEnabled
        description
        duration
        expectedVisitors
        id
        inputUrl
        name
        startsAt
        state
    }
`);

    cache.writeFragment({
        id: cache.identify(data.extendLivestream),
        fragment: extendsLivestreamFragment,
        data: data.extendLivestream
    });
}



export const CREATE_LIVESTREAM_UPDATE_CACHE = (cache, { data }) => {
    cache.modify({
        fields: {
            livestreams(existingLivestreams = []) {
                const newLivestreamRef = cache.writeFragment({
                    data: data.createLivestream,
                    fragment: gql(`
                        fragment NewLivestream on Livestream {
                            embedSnippet
                            embedUrl
                            chatToken
                            chatEnabled
                            description
                            duration
                            expectedVisitors
                            id
                            inputUrl
                            name
                            startsAt
                            state
                        }
                    `)
                });
                return [...existingLivestreams, newLivestreamRef]
            }
        }
    })
}
